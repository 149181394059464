import { Outlet, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetZones } from '@wbk/contentful/api';
import Seo from '@/components/headers/seo';
import ZoneSidebar from '@/components/zone/ZoneSidebar';
import ZonePageSkeleton from '@/components/ui/spinners/ZonePageSkeleton';
import OtherZonesCarousel from '@/components/zone/OtherZonesCarousel';
import Error404 from '@/components/error/404';
import ContentfulImage from '@/components/images/ContentfulImage';

const ZoneDetailPage = () => {
  const { lang, zoneSlug } = useParams<{ lang: Language; zoneSlug: string }>();
  const { t } = useTranslation('common');
  const { data, isLoading } = useGetZones({
    lang,
    limit: 1,
    where: { slug: zoneSlug, visibility_not: null },
  });
  const zone = data?.[0];

  if (isLoading) {
    return <ZonePageSkeleton />;
  }

  if (!zone) {
    return <Error404 />;
  }

  return (
    <>
      {zone.backgroundColor && (
        <style>{`
        main, #explore-top-filters { background-color: ${zone.backgroundColor}; }
        #explore-top-filters button { background: #ffffff20 }
        `}</style>
      )}

      <Seo title={zone.title} />

      <div className='flex flex-col items-start lg:container lg:flex-row lg:gap-6 lg:py-6'>
        <ZoneSidebar zone={zone} />
        <section className='w-full px-4 lg:px-0'>
          {zone.banner && (
            <ContentfulImage
              src={zone.banner.url}
              alt={zone.banner.title}
              className='hidden h-full max-h-[340px] w-full object-cover pb-4 lg:block'
              height='100%'
              placeholder
            />
          )}

          <h1 className='pb-2 text-xl'>{t('common:things_to_do_zone', { zone: zone.title })}</h1>
          <Outlet />
        </section>
      </div>

      <OtherZonesCarousel />
    </>
  );
};

export default ZoneDetailPage;
