import { Fragment } from 'react/jsx-runtime';
import { useWindowResize } from '@wbk/hooks';
import { AdSlotLB, AdSlotMLB, AdSlotMPU, useAds } from '@smc/ads';
import Reveal from '../animation/Reveal';
import EventListItem from '../events/card/ListItem';
import EventItem from '../events/card';
import type { ContentfulEvent } from '@wbk/contentful/api';

type Props = {
  events: ContentfulEvent[];
};

const ExploreGrid = ({ events }: Props) => {
  const { isLessWidth } = useWindowResize({ width: 539 });
  const { exploreType, shouldRenderMpu, canRenderAds } = useAds();

  return events.map((event, i) => {
    const delay = (i % 3) * 0.1;
    const Comp = i > 6 ? Reveal : 'div';

    return (
      <Fragment key={event.id + i}>
        <Comp direction='bottom' delay={i % 2 > 0 ? delay : -delay} className='mx-auto w-full'>
          {isLessWidth ? <EventListItem event={event} /> : <EventItem event={event} />}
        </Comp>

        {/* Ads */}
        {i === 0 && <AdSlotMLB adUnit={`${exploreType}/mlb`} className='mb-0 py-2' />}
        {i === 3 && <AdSlotLB adUnit={`${exploreType}/lb`} className='col-span-full' />}
        {canRenderAds && shouldRenderMpu(events.length, i) && (
          <AdSlotMPU
            slotId={`${event.id}-${i}`}
            adUnit={exploreType}
            className='flex grow flex-col overflow-hidden rounded-md'
          >
            <div className='grow'></div>
          </AdSlotMPU>
        )}
      </Fragment>
    );
  });
};

export default ExploreGrid;
