import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';
import useSearch from '@/context/search/useSearch';
import SearchFeaturedEvents from './SearchFeaturedEvents';
import SearchCategories from './Categories';

const SearchInitialScreen = () => {
  const { t } = useTranslation('common');
  const { searchHistory, onHistoryClear, onSearch } = useSearch();

  return (
    <>
      {searchHistory.length > 0 && (
        <div className='space-y-4'>
          <div className='text-text-400 flex items-center justify-between'>
            <p className='text-xl font-semibold'>{t('common:recent_searches')}</p>
            <Button
              data-testid='header_search_clear_history'
              onClick={onHistoryClear}
              shape='text'
              className='text-text-100 py-1 text-sm font-semibold'
            >
              {t('common:clear')}
            </Button>
          </div>
          <ul className='flex flex-wrap gap-2'>
            {searchHistory.map((term, i) => (
              <li key={i}>
                <Button
                  data-testid={`header_search_history_button_${term}`}
                  onClick={() => onSearch(term)}
                  theme='white'
                  shape='outlined'
                  className='text-text-700 border-text/40 py-1 text-sm'
                  phill
                >
                  <span>{term}</span>
                </Button>
              </li>
            ))}
          </ul>

          <hr className='border-input-border' />
        </div>
      )}

      <SearchCategories />
      <SearchFeaturedEvents />
    </>
  );
};

export default SearchInitialScreen;
