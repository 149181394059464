import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BottomSheet, Button, Checkbox, Modal } from '@wbk/ui';
import { ContentfulEvent } from '@wbk/contentful/api';
import { useDeviceDetect } from '@wbk/hooks';
import { useQueue } from '@wbk/queue';
import BuyTicketButton from '../events/buttons/BuyButton';
import CloseButton from '../buttons/CloseButton';

type Props = {
  event: ContentfulEvent;
};

const JoinAuctionButton = ({ event }: Props) => {
  const { isMobile, isWebView } = useDeviceDetect();
  const { t } = useTranslation(['common', 'event']);
  const [agree, setAgree] = useState(false);
  const { isActive, shouldWaitQueue } = useQueue();

  if (isActive || shouldWaitQueue) {
    return null;
  }

  const renderAction = (open: () => void) => (
    <>
      <style>{`
        @media (max-width: 1023px) {
        #root footer {margin-bottom:100px;}
        }`}</style>
      <div
        className={`bg-body-light fixed bottom-0 left-0 z-20 flex min-h-[70px] w-full items-start justify-center border-t border-white/10 px-4 ${isWebView ? 'pb-8 pt-2' : 'safe-bottom py-2 pb-6 lg:px-0 lg:py-0'} lg:relative lg:border-none lg:bg-transparent`}
      >
        <Button
          className='buy-ticket mx-auto min-h-[50px] w-full max-w-xl text-lg font-bold capitalize leading-none lg:max-w-none'
          disabled={event.isComingSoon || !event.ticketingUrlSlug}
          onClick={open}
        >
          {event.buttonLabel || t('event:buy_tickets')}
        </Button>
      </div>
    </>
  );

  const Body = (
    <>
      <div className='bg-gray-900 p-4 py-6'>
        <img src='/icons/logo.svg' className='mx-auto h-16 w-full opacity-20' alt='webook logo' />
      </div>
      <div className='space-y-4 p-4'>
        <p className='text-lg font-semibold'>{t('event:auction.before_joining')}</p>
        <ol className='space-y-2 ltr:pl-2 rtl:pr-2'>
          {[1, 2, 3, 4].map((i) => (
            <li key={i} className='flex gap-2 text-sm'>
              <p className='flex h-7 w-7 shrink-0 items-center justify-center rounded-full border p-2'>
                {i}
              </p>
              <p>{t(`event:auction.before_joining_${i}`)}</p>
            </li>
          ))}
        </ol>

        <Checkbox
          label={t('event:auction.agree')}
          groupClassName='text-sm'
          className='mt-px'
          checked={agree}
          onChange={() => setAgree((prev) => !prev)}
        />
      </div>
    </>
  );

  const renderFooter = (close: () => void) => (
    <div className='space-y-3 p-4 pb-8 lg:px-0 lg:pb-2'>
      {agree ? (
        <BuyTicketButton
          event={{ ...event, startingPrice: 0 }}
          className='max-w-none font-normal'
        />
      ) : (
        <Button className='min-h-[50px] w-full grow text-lg capitalize leading-none' disabled>
          {event.buttonLabel || t('event:buy_tickets')}
        </Button>
      )}
      <Button
        onClick={close}
        theme='transparent'
        className='bg-body-lighter ring-offset-body-lighter hover:bg-body-light focus:bg-body-light min-h-[50px] w-full shrink-0 px-6 text-white'
      >
        {t('common:cancel')}
      </Button>
    </div>
  );

  return isMobile ? (
    <BottomSheet
      closeButton
      rounded
      render={({ open }) => renderAction(open)}
      body={() => <div className='pb-48'>{Body}</div>}
      stickyFooter={({ close }) => renderFooter(close)}
    />
  ) : (
    <Modal
      className='overflow-hidden'
      render={({ open }) => renderAction(open)}
      body={({ close }) => (
        <>
          <CloseButton onClick={close} />
          <div className='pb-36'>{Body}</div>
        </>
      )}
      stickyFooter={({ close }) => renderFooter(close)}
    />
  );
};

export default JoinAuctionButton;
