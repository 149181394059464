import { useCallback, useEffect } from 'react';
import { useLoaderData, useParams, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Exception } from '@wbk/logger';
import { createCookie } from '@wbk/utils';

const WebviewLogin = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const redirect = (useLoaderData() as undefined | { redirect: string })?.redirect;

  const decryptAppToken = useCallback(async () => {
    const lang = params.lang;
    const type = params.type;
    const slug = params.slug;
    const enc = searchParams.get('enc');
    const currency = searchParams.get('currency');

    try {
      if (currency) {
        createCookie({ name: 'currency', value: currency.toUpperCase(), expiration: 1 });
      }

      if (!enc) {
        throw new Error('No enc param');
      }

      createCookie({
        name: 'token',
        value: enc,
        expiration: 7,
        domain: process.env.VITE_COOKIE_DOMAIN,
      });

      queryClient.invalidateQueries({ queryKey: ['user'] });
    } catch (error) {
      new Exception({
        error,
        name: 'decryptAppTokenException',
        context: { extra: { params, enc } },
      });
    } finally {
      // Using window.location.replace to full refresh the
      // page to make sure Queue can catch the user

      if (redirect) {
        // eslint-disable-next-line no-unsafe-finally
        return window.location.replace(`/${lang}${redirect}`);
      }

      // If type === auctions, go to detail page
      if (type === 'auctions') {
        window.location.replace(`/${lang}/${type}/${slug}`);
      } else {
        window.location.replace(`/${lang}/${type}/${slug}/book`);
      }
    }

    // eslint-disable-next-line
  }, [params, searchParams, redirect]);

  useEffect(() => {
    decryptAppToken();
  }, [decryptAppToken]);

  return null;
};

export default WebviewLogin;
