import { useGetZones } from '@wbk/contentful/api';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from '@wbk/hooks';
import useLocalization from '@/context/localization/useLocalization';
import useFilters from '@/context/filters/useSearch';
import FilterCheckboxList from './CheckboxList';

const ExploreZoneFilters = () => {
  const { locale, countryCode } = useLocalization();
  const { t } = useTranslation('common');
  const { isMobile } = useDeviceDetect();
  const { zone, selectFilter, country } = useFilters();
  const code = country || countryCode;
  const { data, isPending } = useGetZones(
    {
      lang: locale,
      where: { location: { countryCode: code } },
    },
    {
      enabled: !!code,
    }
  );

  return (
    <FilterCheckboxList
      title={t('common:zone')}
      list={data?.map((zone) => ({ title: zone.title, value: zone.slug }))}
      value={zone || []}
      onChange={(value) => selectFilter({ key: 'zone', value: [value], apply: !isMobile })}
      loading={isPending}
    />
  );
};

export default ExploreZoneFilters;
