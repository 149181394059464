import { ContentfulEvent } from '@wbk/contentful/api';
import { BellIcon, ClockIcon, ThunderIcon, UpRightIcon } from '@wbk/svg';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type Props = {
  type: ContentfulEvent['eventType'];
  className?: string;
  mini?: boolean;
};

type TypeProps = {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
};

const VARIABLES: Record<Props['type'], TypeProps | null> = {
  internal: {
    Icon: ThunderIcon,
  },
  external: {
    Icon: UpRightIcon,
  },
  '24_hours_confirmation': {
    Icon: ClockIcon,
  },
  offline: {
    Icon: BellIcon,
  },
  offline_experience: {
    Icon: BellIcon,
  },
  online_experience: null,
};

const EventBookingTypeBadge = ({ type, mini, className }: Props) => {
  const { t } = useTranslation(['event']);

  const vars = VARIABLES[type];

  if (!vars) {
    return null;
  }

  if (mini) {
    const miniText = t(`event:${type}_mini`);
    const text = miniText === `${type}_mini` ? t(`event:${type}`) : miniText;
    return (
      <div className={twMerge('flex items-center gap-1 rounded-md text-xs', className)}>
        <div>
          <vars.Icon className='h-4 w-4' />
        </div>
        <p className='leading-none'>{text}</p>
      </div>
    );
  }

  return (
    <div className={twMerge('flex items-center gap-3 rounded-md p-2', className)}>
      <div className='rounded-md text-white'>
        <vars.Icon className='h-8 w-8' />
      </div>
      <div>
        <p className='text-lg font-semibold'>{t(`event:${type}`)}</p>
        <p className='text-text text-sm'>{t(`event:${type}_desc`)}</p>
      </div>
    </div>
  );
};

export default EventBookingTypeBadge;
