import { useState } from 'react';
import { useWebsiteLocale } from '@wbk/hooks';
import { Button, Gallery, ImageWithPlaceholder } from '@wbk/ui';
import { ContentfulEvent } from '@wbk/contentful/api';
import { useTranslation } from 'react-i18next';
import GalleryWithThumbs from './GalleryWithThumbs';

type Props = {
  event: Pick<ContentfulEvent, 'title' | 'image11' | 'image31' | 'carousalCollection'>;
};

const EventGallery = ({ event }: Props) => {
  const { shortLang } = useWebsiteLocale();
  const { t } = useTranslation('event');
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = event.carousalCollection?.items || [event.image11];
  const list = images.slice(0, 2);
  const sublist = images.slice(2, 4);
  const showMoreButton = images.length > 2;

  if (images.length <= 1) {
    return (
      <>
        <ImageWithPlaceholder
          ratio='3x1'
          className='aspect-3 hidden w-full rounded-md object-cover object-center md:block'
          src={event.image31.url}
          alt={event.image31.title || event.title}
        />
        <ImageWithPlaceholder
          className='mx-auto w-full max-w-md rounded-md object-cover object-center md:hidden'
          src={event.image11.url}
          alt={event.image11.title || event.title}
        />
      </>
    );
  }

  return (
    <>
      <Gallery
        locale={shortLang}
        startIndex={currentIndex}
        render={({ open }) => {
          return (
            <div data-images={images.length} className='relative hidden gap-2 sm:flex'>
              {list.map((poster, i) => (
                <Button
                  key={i}
                  onClick={() => {
                    open();
                    setCurrentIndex(i);
                  }}
                  theme='white'
                  className='relative aspect-[1/1] max-h-[400px] w-full flex-1 bg-transparent hover:bg-transparent [&:nth-child(1)]:basis-[20%] [&:nth-child(2)]:basis-[20%]'
                >
                  <ImageWithPlaceholder
                    src={poster.url}
                    className='absolute inset-0 h-full w-full cursor-pointer rounded-md object-cover'
                    alt='restaurant-test'
                  />
                </Button>
              ))}
              {sublist.length > 1 && (
                <div className='hidden flex-1 space-y-2 md:block'>
                  {sublist.map((poster, i) => (
                    <Button
                      key={i}
                      onClick={() => {
                        open();
                        setCurrentIndex(i);
                      }}
                      theme='white'
                      className='aspect-1 relative max-h-[196px] w-full flex-1 bg-transparent hover:bg-transparent'
                    >
                      <ImageWithPlaceholder
                        src={poster.url}
                        className='absolute inset-0 h-full w-full cursor-pointer rounded-md object-cover'
                        alt='restaurant-test'
                      />
                    </Button>
                  ))}
                </div>
              )}

              {showMoreButton && (
                <Button
                  theme='white'
                  shape='solid'
                  data-images={images.length}
                  className='absolute bottom-2 text-xs capitalize data-[images=4]:md:hidden ltr:right-7 rtl:left-7'
                  onClick={() => {
                    open();
                    setCurrentIndex(images.length + 1);
                  }}
                >
                  {t('event:see_all_photos', { number: images.length })}
                </Button>
              )}
            </div>
          );
        }}
        slides={images.map((img) => ({
          src: img.url,
          title: img.title || event.title,
        }))}
      />

      <GalleryWithThumbs className='sm:hidden' poster={event.image11} carousel={images} />
    </>
  );
};

export default EventGallery;
