import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AnalyticsProvider } from '@wbk/analytics';
import { AuthProvider } from '@wbk/auth';
import { Trans, useTranslation } from 'react-i18next';
import { HyExperienceProvider } from '@hy/experiences';
import { WbkTicketingProvider } from '@wbk/ticketing';
import { WbkPackagesProvider } from '@wbk/packages';
import { WbkQueueProvider } from '@wbk/queue';
import { SmcAdsProvider } from '@smc/ads';
import { UiProvider } from '@wbk/ui';
import { SubscriptionProvider } from '@wbk/subscription';
import { CurrencyProvider } from '@wbk/currency';
import Layout from './components/layout';
import ScrollToTop from './components/common/ScrollToTop';
import ErrorBoundary from './components/error/ErrorBoundary';
import CookieConsent from './components/common/CookieConsent';
import { POSTER_1X1_FADED, POSTER_3X1_FADED } from './constants/fallbackLogo';
import { SUBSCRIPTIONS_SLUG } from './constants/subscriptions';
import useLocalization from './context/localization/useLocalization';
import { gtmTracker, postHogTracker } from '../trackers.config';

type Props = {
  type?: LayoutType;
};

const AppLayout = ({ type }: Props) => {
  const { pathname } = useLocation();
  const { locale, detectedCurrency } = useLocalization();
  const navigate = useNavigate();

  return (
    <ErrorBoundary>
      <UiProvider
        dir={locale.startsWith('ar') ? 'rtl' : 'ltr'}
        imgFallbacks={{ '1x1': POSTER_1X1_FADED, '3x1': POSTER_3X1_FADED }}
      >
        <AnalyticsProvider trackers={[gtmTracker, postHogTracker]} currentLocation={pathname}>
          <CurrencyProvider
            locale={locale}
            detectedCurrency={detectedCurrency}
            i18n={{ useTranslation, Trans }}
          >
            <AuthProvider
              locale={locale}
              autoInit={false}
              appSource={process.env.VITE_PUBLIC_APP_SOURCE}
              i18n={{ useTranslation, Trans }}
              router={{ Link, push: navigate, replace: (u) => navigate(u, { replace: true }) }}
            >
              <SubscriptionProvider
                locale={locale}
                slug={SUBSCRIPTIONS_SLUG}
                i18n={{ useTranslation, Trans }}
                bookingSource={process.env.VITE_PUBLIC_APP_SOURCE!}
                router={{ Link, push: navigate, replace: (u) => navigate(u, { replace: true }) }}
                active={false}
              >
                <WbkTicketingProvider
                  locale={locale}
                  bookingSource={process.env.VITE_PUBLIC_APP_SOURCE!}
                  resell={{ domain: process.env.VITE_PUBLIC_RESELL_DOMAIN }}
                  seatsIoWorkspace={process.env.VITE_PUBLIC_SEATIO_WORKSPACE_KEY}
                  i18n={{ useTranslation, Trans }}
                  router={{ Link, push: navigate, replace: (u) => navigate(u, { replace: true }) }}
                >
                  <WbkPackagesProvider
                    bookingSource={process.env.VITE_PUBLIC_APP_SOURCE!}
                    locale={locale}
                    i18n={{ useTranslation, Trans }}
                    router={{
                      Link,
                      push: navigate,
                      replace: (u) => navigate(u, { replace: true }),
                    }}
                  >
                    <WbkQueueProvider locale={locale} i18n={{ useTranslation, Trans }}>
                      <SmcAdsProvider
                        env={
                          process.env.VITE_PUBLIC_ENV === 'production'
                            ? 'production'
                            : 'development'
                        }
                      >
                        <HyExperienceProvider
                          locale={locale}
                          i18n={{ useTranslation, Trans }}
                          router={{
                            Link,
                            push: navigate,
                            replace: (u) => navigate(u, { replace: true }),
                          }}
                        >
                          <ScrollToTop />
                          <Layout type={type} />
                          <CookieConsent />
                        </HyExperienceProvider>
                      </SmcAdsProvider>
                    </WbkQueueProvider>
                  </WbkPackagesProvider>
                </WbkTicketingProvider>
              </SubscriptionProvider>
            </AuthProvider>
          </CurrencyProvider>
        </AnalyticsProvider>
      </UiProvider>
    </ErrorBoundary>
  );
};

export default AppLayout;
