import React from 'react';
import { RouteObject } from 'react-router-dom';
import Error404 from '@/components/error/404';
const PlanBookingPage = React.lazy(() => import('@/pages/subscriptions/book'));
const PlanUpgradePage = React.lazy(() => import('@/pages/subscriptions/upgrade'));
const PlansPage = React.lazy(() => import('@/pages/subscriptions'));
const ManageSubscriptionsPage = React.lazy(() => import('@/pages/subscriptions/manage'));

export const subscriptionRoutes: RouteObject[] = [
  {
    path: 'subscriptions',
    errorElement: <Error404 />,
    element: <PlansPage />,
  },
  {
    path: 'subscriptions/book/:id',
    element: <PlanBookingPage />,
    errorElement: <Error404 />,
  },
  {
    path: 'subscriptions/manage/:id',
    element: <ManageSubscriptionsPage />,
    errorElement: <Error404 />,
  },
  {
    path: 'subscriptions/upgrade/:id',
    element: <PlanUpgradePage />,
    errorElement: <Error404 />,
  },
];
