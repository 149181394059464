import { SocialBanner, WebSection } from '@wbk/contentful/api';
import ContentfulButton from '../buttons/ContentfulButton';
import ContentfulImage from '../images/ContentfulImage';

type Props = {
  section: WebSection;
};

const SocialBannerSection = ({ section }: Props) => {
  const socialBanner = section.contentCollection.items[0] as SocialBanner;

  if (!socialBanner) return null;

  return (
    <section
      data-testid={`home_social_banner_${section.id}`}
      className='container flex flex-col justify-between gap-6 py-12 text-start md:flex-row md:items-center'
    >
      <div className='space-y-6'>
        <h2>{socialBanner.title}</h2>
        <ul className='flex flex-wrap items-center gap-6'>
          {socialBanner.socialLinks?.iconLinksCollection?.items?.map((link) => (
            <li key={link.id} className='rounded-md'>
              <ContentfulButton
                link={link}
                rel='noreferrer noopener'
                className='social_media_follow shrink-0 overflow-hidden rounded-md !p-1'
                data-location='footer'
                data-category={link.label}
                rounded
              />
            </li>
          ))}
        </ul>
      </div>

      {socialBanner.logoImage && (
        <ContentfulImage
          className='mx-auto w-full max-w-[400px] shrink-0 md:mx-0'
          src={socialBanner.logoImage.url}
          alt={socialBanner.logoImage.title}
        />
      )}
    </section>
  );
};

export default SocialBannerSection;
