import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWebsiteLocale } from '@wbk/hooks';
import { createCookie, readCookie } from '@wbk/utils';
import { useGeoLocation } from '@wbk/api/hooks';
import Context, { FALLBACK_COUNTRY } from './context';

type Props = {
  children: React.ReactNode;
};

const LocalizationProvider = ({ children }: Props) => {
  const { i18n } = useTranslation('common');
  const { shortLang } = useWebsiteLocale();
  const [state, setState] = useState({ locale: shortLang });
  const savedLocation = readCookie('location') || '';
  const savedCurrency = readCookie('currency') || '';
  // Error is ignored here
  const { data, isPending } = useGeoLocation({
    enabled: !savedLocation,
    initialData: { country_name: savedLocation, currency: savedCurrency, is_supported: true },
  });
  const validCode = data?.country_name && /^[A-Z]{2}$/.test(data.country_name);
  const detectedCountryCode = validCode ? data?.country_name : FALLBACK_COUNTRY;
  const isSupportedCountry = typeof data?.is_supported === 'boolean' ? data?.is_supported : true;
  const countryCode = isSupportedCountry ? detectedCountryCode : null;
  // Priority to search param
  const paramCurrency = new URLSearchParams(window.location.search).get('currency')?.toUpperCase();
  const detectedCurrency = paramCurrency || data?.currency || 'SAR';

  useEffect(() => {
    if (shortLang) {
      setLocale(shortLang);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Save the detected country code in a cookie for 24h
    if (countryCode) {
      createCookie({ name: 'location', value: countryCode, expiration: 1 });
      createCookie({ name: 'currency', value: detectedCurrency, expiration: 1 });
    }
  }, [countryCode, isSupportedCountry, detectedCurrency]);

  const setLocale = async (language: string) => {
    await i18n.changeLanguage(language);
    setState((prevState) => ({ ...prevState, locale: language }));
    createCookie({ name: 'lang', value: language, expiration: 30 });
    document.documentElement.lang = language;
    document.documentElement.dir = language.startsWith('ar') ? 'rtl' : 'ltr';
  };

  return (
    <Context.Provider
      value={{
        ...state,
        detectedCountryCode,
        countryCode,
        detectedCurrency,
        isSupportedCountry,
        fallbackCountry: FALLBACK_COUNTRY,
        detectLoading: isPending,
        setLocale,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default LocalizationProvider;
