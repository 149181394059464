import { ContentfulLocation } from '@wbk/contentful/api';
import { useWebsiteLocale } from '@wbk/hooks';
import { twMerge } from 'tailwind-merge';
import { getSafeCountryName } from '@wbk/utils';

type Props = {
  location?: ContentfulLocation;
  className?: string;
};

const EventLocation = ({ location, className }: Props) => {
  const { shortLang } = useWebsiteLocale();

  const countryCode = location?.countryCode || '';
  const country = getSafeCountryName({ countryCode, locale: shortLang });
  const city = location?.city || '';

  const text = city && country ? `${city}, ${country}` : city || country;

  if (!text) return null;

  return <p className={twMerge('line-clamp-1 text-sm', className)}>{text}</p>;
};

export default EventLocation;
