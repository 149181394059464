import { useTranslation } from 'react-i18next';
import BuyTicketButton from './buttons/BuyButton';
import EventStartPrice from './StartPrice';
import EventBookingTypeBadge from './BookingType';
import ResellBanner from './ResellBanner';
import NotifyButton from './buttons/NotifyButton';
import EventAccessibilityButton from './buttons/AccessibilityButton';
import type { ContentfulEvent } from '@wbk/contentful/api';

type Props = {
  event: ContentfulEvent;
  accessibility?: boolean;
};

const EventDetailSidebar = ({ event, accessibility }: Props) => {
  const { t } = useTranslation(['event']);

  return (
    <div className='border-text/20 top-20 space-y-4 rounded-xl border p-4 lg:sticky'>
      <h2 className='text-2xl'>{t('event:book_spot')}</h2>
      {event.eventType?.includes('offline') ? (
        <NotifyButton
          event={event}
          type={event.__typename === 'Experience' ? 'experience' : 'event'}
        />
      ) : (
        <>
          <EventStartPrice event={event} />
          <BuyTicketButton event={event} className='max-w-md' />
          {!!accessibility && (
            <div>
              <EventAccessibilityButton event={event} className='max-w-md' />
            </div>
          )}
          <hr className='border-text/20 !my-6' />
        </>
      )}

      <EventBookingTypeBadge type={event.eventType} />
      <ResellBanner
        slug={event.ticketingUrlSlug || event.slug}
        show={event?.showResellBanner}
        mini
      />
    </div>
  );
};

export default EventDetailSidebar;
