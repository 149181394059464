import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, Modal } from '@wbk/ui';
import { useWindowScroll } from '@wbk/hooks';
import MobileSearchContent from '@/components/search/MobileSearchContent';
import useLocalization from '@/context/localization/useLocalization';
import useSearch from '@/context/search/useSearch';

const MobileSearch = () => {
  const { y } = useWindowScroll();
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { locale } = useLocalization();
  const { showPopover, togglePopover } = useSearch();
  const isHome = pathname?.replace(locale, '') === '/';
  const show = !isHome || y > 150;

  return (
    <AnimatePresence initial={false}>
      {show && (
        <Modal
          fullscreen
          ignoreHistory
          unstyled
          show={showPopover}
          onClose={() => togglePopover(false)}
          className='data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-top-[45%] data-[state=open]:slide-in-from-top-[45%] fixed top-0 z-50 duration-1000'
          render={() => (
            <motion.div
              key={pathname}
              className='w-full overflow-hidden md:max-w-[180px]'
              initial={{ width: 0, padding: 0 }}
              animate={{ width: '100%', padding: '0 1px' }}
              exit={{ width: 0, padding: 0 }}
              transition={{ duration: 0.4 }}
            >
              <Button
                shape='text'
                theme='white'
                className='bg-body border-input-border my-px w-full max-w-xs grow justify-start truncate border p-2 sm:px-1'
                onClick={() => togglePopover(true)}
                data-testid='header_mobile_search_button'
                data-location='header'
                data-title='search'
              >
                <img
                  className='h-3.5 w-3.5 shrink-0 opacity-50 sm:h-4 sm:w-4'
                  src='/icons/common/search.svg'
                  alt=''
                />
                <p className='text-text-700 shrink-0 text-[10px] sm:text-xs'>
                  {t('common:search_placeholder')}
                </p>
              </Button>
            </motion.div>
          )}
          body={() => <MobileSearchContent />}
        />
      )}
    </AnimatePresence>
  );
};

export default MobileSearch;
