import { POSTER_1X1_FADED } from '@/constants/fallbackLogo';

const EventItemSkeleton = ({ number = 6, mobile = false }) => {
  const array = Array.from(Array(number).keys());

  if (mobile) {
    return array.map((i) => (
      <div key={i} className='flex w-full justify-start gap-3 p-0.5 text-start'>
        <div className='bg-body-light/50 h-24 w-24 shrink-0 animate-pulse rounded-md' />
        <div className='grow space-y-2'>
          <div className='bg-body-light/50 h-4 w-20 animate-pulse rounded-md' />
          <div className='bg-body-light/50 h-4 animate-pulse rounded-md' />
          <div className='bg-body-light/50 h-4 w-40 animate-pulse rounded-md' />
          <div className='bg-body-light/50 h-4 w-20 animate-pulse rounded-md' />
        </div>
      </div>
    ));
  }

  return array.map((i) => (
    <div
      key={i}
      className='relative mx-auto flex aspect-[2/2.5] w-full max-w-sm animate-pulse items-center justify-center overflow-hidden rounded-md'
    >
      <img src={POSTER_1X1_FADED} className='h-full w-full object-cover' alt='' />
    </div>
  ));
};

export default EventItemSkeleton;
