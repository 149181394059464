import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import { ContentfulEvent } from '@wbk/contentful/api';
import { CurrencyPriceLabel } from '@wbk/currency';
import FreeStreamButton from '../buttons/FreeStreamButton';

type Props = {
  event: ContentfulEvent;
};

const StreamBanner = ({ event }: Props) => {
  const { lang } = useParams<{ lang: Language }>();
  const { t } = useTranslation('event');
  const streaming = event.buyLiveStream;
  const hasValidStream = !!streaming && streaming?.button?.link;

  if (!hasValidStream) return null;

  const isFree = !event.startingPrice;

  return (
    <div className='relative flex flex-col items-center justify-between gap-4 rounded-md bg-[#352F81] p-4 before:absolute before:inset-0 before:bg-[repeating-linear-gradient(-45deg,rgba(255,255,255,0.04)_1%,transparent_1%,transparent_5%,rgba(255,255,255,0.04)_5%,rgba(255,255,255,0.04)_8%)] before:bg-repeat md:flex-row'>
      <div className='flex gap-2'>
        <div className='shrink-0'>
          <img
            src={event.buttonIcon?.url || '/icons/common/live-tv.svg'}
            width={35}
            height={35}
            alt=''
          />
        </div>
        <div className='grow'>
          <p className='text-lg font-semibold'>{streaming.title}</p>
          {streaming.description && <p className='text-sm'>{streaming.description}</p>}

          <div className='space-y-2 py-2'>
            {streaming.limitations && (
              <div className='flex items-center gap-1 text-xs text-gray-400'>
                <img
                  className='p-0.5 opacity-40'
                  src={'/icons/common/lock.svg'}
                  width={20}
                  height={20}
                  alt=''
                />
                <p>{streaming.limitations}</p>
              </div>
            )}
            <div className='flex items-center gap-1 text-xs text-gray-400'>
              <img src={'/icons/common/iphone.svg'} width={20} height={20} alt='' />
              <p>{t('event:stream.app_only')}</p>
            </div>
          </div>
        </div>
      </div>

      {isFree ? (
        <FreeStreamButton
          className='shrink-0 px-6 py-2 text-sm'
          theme='white'
          shape='outlined'
          arrow
        >
          {t('event:watch_free_stream_app')}
        </FreeStreamButton>
      ) : (
        <div className='flex shrink-0 items-center gap-4'>
          <div>
            <div className='flex items-center gap-1'>
              <span>{t('event:starts_from')}</span>
              <CurrencyPriceLabel
                originalPrice={Number(streaming.startingPrice)}
                currency={streaming.currencyCode}
                priceClassName='font-semibold text-lg'
              />
            </div>
            {streaming.taxInfo && <p className='text-[11px] text-gray-400'>{streaming.taxInfo}</p>}
          </div>

          <Button
            as={Link}
            className='py-1'
            to={`/${lang}/events/${streaming.button?.link}/book`}
            theme='white'
            shape='outlined'
            arrow
          >
            {streaming.button?.label || t('event:buy_stream')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default StreamBanner;
