import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  getEventListing,
  getExperiences,
  getPackages,
  getRestaurants,
  getShows,
} from '@wbk/contentful/api';
import useLocalization from '@/context/localization/useLocalization';
import useExploreQuery from './useExploreQuery';

type Props = {
  predefined?: Parameters<typeof useExploreQuery>[0]['predefined'];
  enabled?: boolean;
};

const useExploreFetch = ({ predefined, enabled = true }: Props) => {
  const { detectLoading } = useLocalization();
  const { query, perPage } = useExploreQuery({ predefined });
  const canFetch = !!query && enabled;

  const {
    fetchNextPage: fetchMoreEvents,
    hasNextPage: hasMoreEvents,
    isFetching: eventsLoading,
    data: events,
  } = useInfiniteQuery({
    queryKey: ['getEvents', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getEventListing({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: canFetch,
  });

  const {
    fetchNextPage: fetchMoreExperiences,
    hasNextPage: hasMoreExperiences,
    isFetching: experiencesLoading,
    data: experiences,
  } = useInfiniteQuery({
    queryKey: ['getExperiences', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getExperiences({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: canFetch,
  });

  const {
    fetchNextPage: fetchMoreShows,
    hasNextPage: hasMoreShows,
    isFetching: showsLoading,
    data: shows,
  } = useInfiniteQuery({
    queryKey: ['getShows', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getShows({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: canFetch,
  });

  const {
    fetchNextPage: fetchMoreRestaurants,
    hasNextPage: hasMoreRestaurants,
    isFetching: restaurantsLoading,
    data: restaurants,
  } = useInfiniteQuery({
    queryKey: ['getRestaurants', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getRestaurants({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: canFetch,
  });

  const {
    fetchNextPage: fetchMorePackages,
    hasNextPage: hasMorePackages,
    isFetching: packagesLoading,
    data: packages,
  } = useInfiniteQuery({
    queryKey: ['getPackages', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getPackages({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: canFetch,
  });

  const allEvents = useMemo(() => {
    if (!canFetch) {
      return { total: 0, items: [] };
    }

    const allEvents = events?.pages || [];
    const allExperiences = experiences?.pages || [];
    const allShows = shows?.pages || [];
    const allRestaurants = restaurants?.pages || [];
    const allPackages = packages?.pages || [];

    // A hack for the sorting
    const queries = [allEvents, allExperiences, allShows, allRestaurants, allPackages];
    const all = queries.flat();
    const sortedBySettledAt = all.sort((a, b) => a.settledAt - b.settledAt).flatMap((p) => p.items);

    const total = queries.reduce((acc, q) => acc + (q[0]?.total || 0), 0);

    return {
      total,
      items: sortedBySettledAt,
    };
  }, [
    canFetch,
    events?.pages,
    experiences?.pages,
    packages?.pages,
    restaurants?.pages,
    shows?.pages,
  ]);

  const loading =
    detectLoading ||
    eventsLoading ||
    experiencesLoading ||
    restaurantsLoading ||
    showsLoading ||
    packagesLoading;

  return {
    allEvents: allEvents.items,
    total: allEvents.total,
    hasMoreEvents,
    hasMoreExperiences,
    hasMoreRestaurants,
    hasMoreShows,
    hasMorePackages,
    fetchMoreEvents,
    fetchMoreExperiences,
    fetchMoreRestaurants,
    fetchMoreShows,
    fetchMorePackages,
    loading,
  };
};

export default useExploreFetch;
