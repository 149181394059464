import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { BottomSheet, Button, Modal, Toast } from '@wbk/ui';
import { User } from '@wbk/api';
import { ReferralCard } from '@wbk/contentful/api';
import { useDeviceDetect } from '@wbk/hooks';
import { AuthForm, useAuth } from '@wbk/auth';
import CloseButton from '../buttons/CloseButton';
import ContentfulRichText from '../text/ContentfulRichText';
import StaticCheck from '../svg/StaticCheck';
import ContentfulImage from '../images/ContentfulImage';

type Props = {
  section: ReferralCard;
  className?: string;
  mini?: boolean;
};

const ReferralBanner = ({ section, className = '', mini }: Props) => {
  const { t } = useTranslation('common');
  const { isMobile } = useDeviceDetect();
  const { user } = useAuth({ autoFetch: true });

  const renderAction = useCallback(
    (open: () => void) => {
      if (mini) {
        return (
          <button
            className={twMerge('flex w-full grow items-center gap-4 text-start', className)}
            onClick={() => {
              open();
            }}
            aria-label={section.title}
          >
            <ContentfulImage
              src={section.banner.url}
              alt={section.banner.title}
              className='w-full max-w-[65px]'
            />
            <div>
              <p className='inline-block leading-none'>
                {section.title}
                <img
                  src='/icons/common/arrow.svg'
                  className='mb-1 inline-flex h-5 w-5 rtl:rotate-180'
                  alt=''
                />
              </p>
              {section.description && (
                <p className='text-xs text-gray-400'>{section.description}</p>
              )}
            </div>
          </button>
        );
      }

      return (
        <Button
          className='w-full max-w-[200px]'
          arrow
          onClick={() => {
            open();
          }}
        >
          {t('common:get_your_link')}
        </Button>
      );
    },
    [
      className,
      mini,
      section.banner.title,
      section.banner.url,
      section.description,
      section.title,
      t,
    ]
  );

  const ModalRender = isMobile ? (
    <BottomSheet
      fullscreen={!!user}
      render={({ open }) => {
        return renderAction(open);
      }}
      body={({ close }) => (
        <>
          <div className={`flex justify-end ${user ? '-mb-10' : ''}`}>
            <CloseButton
              onClick={close}
              className='relative mx-4 bg-white/5 hover:bg-white/10 focus:bg-white/10'
            />
          </div>

          {user ? (
            <ReferralContent section={section} user={user} />
          ) : (
            <div className='pb-12 lg:pb-2'>
              <AuthForm title={t('common:auth.login')} />
            </div>
          )}
        </>
      )}
    />
  ) : (
    <Modal
      className='overflow-hidden'
      render={({ open }) => {
        return renderAction(open);
      }}
      body={({ close }) => (
        <div className='max-h-screen overflow-auto'>
          <div className={`flex justify-end ${user ? '-mb-10' : ''}`}>
            <CloseButton
              onClick={close}
              className='relative mx-4 bg-white/5 hover:bg-white/10 focus:bg-white/10'
            />
          </div>
          {user ? (
            <ReferralContent section={section} user={user} />
          ) : (
            <div className='pb-12 lg:pb-2'>
              <AuthForm title={t('common:auth.login')} />
            </div>
          )}
        </div>
      )}
    />
  );

  if (mini) {
    return ModalRender;
  }

  return (
    <section className='container'>
      <div
        className='flex flex-col items-center justify-between gap-4 overflow-hidden rounded-lg border border-white/5 bg-cover bg-[0%_65%] bg-no-repeat p-4 py-6 md:px-6 lg:flex-row'
        style={{ backgroundImage: `url(${section.backgroundImage.url})` }}
      >
        <div className='flex grow flex-col items-center gap-4 lg:flex-row'>
          <ContentfulImage
            src={section.banner.url}
            alt={section.banner.title}
            className='w-full max-w-[160px]'
          />
          <div className='text-center lg:text-justify'>
            <h2 className='text-xl'>{section.title}</h2>
            {section.description && <p className='text-sm'>{section.description}</p>}
          </div>
        </div>

        <div className='w-full grow text-center lg:w-auto lg:text-end'>{ModalRender}</div>
      </div>
    </section>
  );
};

type ContentProps = Props & { user: User };
const ReferralContent = ({ section, user }: ContentProps) => {
  const [copied, setCopied] = useState(false);
  const toast = useRef<React.ComponentRef<typeof Toast>>();
  const { t } = useTranslation('common');
  const referralLink = `${process.env.VITE_PUBLIC_BOOK_DOMAIN}/u/${user?.referral_share_key}`;

  const copyToClipboard = async () => {
    try {
      navigator.clipboard.writeText(referralLink);
      setCopied(true);
    } catch (error) {
      toast.current?.publish({
        title: t('common:copy_link_error'),
        type: 'error',
      });
    }
  };

  return (
    <>
      <Toast ref={toast} swipeDirection='up' />
      <div
        className='space-y-8 bg-contain bg-[50%_0%] bg-no-repeat p-4 pb-16'
        style={{ backgroundImage: `url(${section.backgroundImage.url})` }}
      >
        <div className='space-y-4'>
          <ContentfulImage
            src={section.banner.url}
            alt={section.banner.title}
            className='mx-auto w-full max-w-[160px]'
          />
          <div className='text-center'>
            <h2 className='text-xl'>{section.title}</h2>
            {section.description && <p className='text-sm'>{section.description}</p>}
          </div>
        </div>

        <div className='space-y-2'>
          <p className='text-xl font-semibold'>{t('common:your_link')}</p>
          <div
            className='flex cursor-pointer items-center justify-between rounded-sm border border-dashed border-white/50 bg-white/5 p-2 hover:bg-white/10'
            onPointerDown={copyToClipboard}
          >
            <p className='truncate text-sm'>{referralLink}</p>
            <Button
              theme={copied ? 'success' : 'primary'}
              className='shrink-0 px-2.5 py-1 text-xs'
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard();
              }}
            >
              {copied ? (
                <StaticCheck className='text-success-light h-4 w-4' />
              ) : (
                <img src='/icons/common/copy.svg' className='h-4 w-4' alt='' />
              )}
              {t(`common:${copied ? 'copied' : 'copy_link'}`)}
            </Button>
          </div>
        </div>

        <div className='space-y-1 rounded-lg bg-white/5 p-3'>
          <p className='text-xl font-semibold'>{t('common:referral_progress')}</p>
          <p className='text-xs text-gray-400'>{section.weekLabel}</p>

          <div className='pt-2 text-center text-xs text-gray-400'>
            <strong>{t('common:referral_progress_total', { count: user?.referral_count })}</strong>
            <p>{t('common:referral_progress_invite')}</p>
          </div>
        </div>

        {section.terms && (
          <div className='space-y-1'>
            <p className='text-xl font-semibold'>{t('common:nav.terms_and_conditions')}</p>
            <div className='text-xs'>
              <ContentfulRichText description={section.terms} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ReferralBanner;
