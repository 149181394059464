import { useMemo } from 'react';
import { Button } from '@wbk/ui';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LocationIcon, SearchIcon } from '@wbk/svg';
import EmptySearchResult from '@/components/search/EmptyResult';
import SearchInitialScreen from '@/components/search/InitialScreen';
import SearchZoneItem from '@/components/search/SearchZoneItem';
import useSearch from '@/context/search/useSearch';
import useLocalization from '@/context/localization/useLocalization';
import EventListItem from '../events/card/ListItem';

const SearchContent = () => {
  const { locale } = useLocalization();
  const { t } = useTranslation('common');
  const {
    searchState,
    allEventsMerged,
    zones,
    search,
    zoneLoading,
    isLoading,
    hasResult,
    hasZoneResult,
    fetchedDone,
    countries,
    togglePopover,
  } = useSearch();

  const locationList = useMemo(() => {
    const list: {
      title: string;
      subtitle?: string;
      code: string;
      subCode?: string;
    }[] = [];

    for (const country of countries || []) {
      const searchVal = searchState?.toLowerCase() || '';
      const filteredCountries = country.title.toLowerCase().includes(searchVal);
      if (filteredCountries) {
        list.push({ title: country.title, code: country.countryCode });
      }

      const filteredCities = country.citiesCollection.items.filter(
        (city) =>
          city.title.toLowerCase().includes(searchVal) ||
          country.title.toLowerCase().includes(searchVal)
      );
      const cities = filteredCities.map((city) => ({
        title: city.title,
        subtitle: country.title,
        code: country.countryCode,
        subCode: city.cityCode,
      }));
      if (cities.length) {
        list.push(...cities);
      }
    }

    return list;
  }, [countries, searchState]);

  return (
    <>
      {!!searchState && (
        <Button
          data-testid='search_see_all_button'
          as={Link}
          to={`/${locale}/search?q=${searchState}`}
          onClick={() => togglePopover(false)}
          className='flex w-full items-center justify-start gap-2 p-0.5 text-start'
          theme='white'
          shape='text'
        >
          <div className='border-input-border flex h-20 w-20 shrink-0 items-center justify-center rounded-md border'>
            <SearchIcon className='mx-auto h-12 w-12' />
          </div>

          <p>{t('common:search_see_all', { search: searchState })}</p>
        </Button>
      )}

      {locationList.length > 0 && (
        <ul className='space-y-2'>
          {locationList.map((location) => {
            const path = location.subCode
              ? `?country=${location.code}&city=${location.subCode}`
              : `?country=${location.code}`;
            const titleWord = location.title;
            const subtitleWord = location.subtitle;
            return (
              <li key={location.code + location.subCode}>
                <Button
                  data-testid={`search_location_${location.code}`}
                  as={Link}
                  to={`/${locale}/explore${path}`}
                  onClick={() => togglePopover(false)}
                  className='flex w-full items-center justify-start gap-2 p-0.5 text-start'
                  theme='white'
                  shape='text'
                >
                  <div className='border-input-border flex h-20 w-20 shrink-0 items-center justify-center rounded-md border'>
                    <LocationIcon className='mx-auto h-12 w-12' />
                  </div>

                  <div className='text-start'>
                    <div className='line-clamp-1' dangerouslySetInnerHTML={{ __html: titleWord }} />
                    {!!subtitleWord && (
                      <div
                        className='text-text-700 line-clamp-1 text-xs'
                        dangerouslySetInnerHTML={{ __html: subtitleWord }}
                      />
                    )}
                  </div>
                </Button>
              </li>
            );
          })}
        </ul>
      )}

      {isLoading || zoneLoading ? (
        <div className='space-y-4'>
          <div className='bg-body-lighter/50 h-20 rounded-md' />
          <div className='bg-body-lighter/50 h-20 rounded-md' />
          <div className='bg-body-lighter/50 h-20 rounded-md' />
        </div>
      ) : hasResult ? (
        <div className='space-y-4'>
          {hasZoneResult && !!zones?.length && (
            <>
              <ul className='space-y-2'>
                {zones.map((zone) => (
                  <li key={zone.slug} className='flex items-center gap-2'>
                    <SearchZoneItem zone={zone} onClick={() => togglePopover(false)} />
                  </li>
                ))}
              </ul>
              <hr className='border-body-lighter !my-4' />
            </>
          )}

          <ul className='space-y-2'>
            {allEventsMerged.map((event) => (
              <li
                key={event.slug}
                className='flex items-center gap-2 [&>a>img]:h-20 [&>a>img]:w-20'
              >
                <EventListItem event={event} onClick={() => togglePopover(false)} />
              </li>
            ))}
          </ul>
        </div>
      ) : search && fetchedDone ? (
        <EmptySearchResult />
      ) : (
        !search && <SearchInitialScreen />
      )}
    </>
  );
};

export default SearchContent;
