import { ContentfulEvent } from '@wbk/contentful/api';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { CurrencyPriceLabel } from '@wbk/currency';

type Props = {
  event: ContentfulEvent;
  className?: string;
};

const EventStartPrice = ({ event, className }: Props) => {
  const { t } = useTranslation(['common', 'event']);

  if (!event.startingPrice) {
    return null;
  }

  return (
    <div className={twMerge('shrink-0', className)}>
      <p className='text-text-700 text-sm'>{t('event:starts_from')}</p>
      <CurrencyPriceLabel
        originalPrice={event.startingPrice}
        discountPrice={event.discountedPrice}
        currency={event.currencyCode}
        changeable
      />
      <p className='text-[11px] text-gray-500'>{t('event:vat_included')}</p>
    </div>
  );
};

export default EventStartPrice;
