import { Link, useParams } from 'react-router-dom';
import { parseSectionUtm } from '@wbk/utils';
import { Button, Carousel } from '@wbk/ui';
import type { WebCard, WebSection } from '@wbk/contentful/api';

type Props = {
  section: WebSection;
};

const CardsCarouselMini = ({ section }: Props) => {
  const cards = section.contentCollection.items as WebCard[];
  const { lang } = useParams<{ lang: Language }>();

  if (!cards?.length) return null;

  return (
    <section
      data-testid={`home_cards_carousel_mini_${section.id}`}
      className='container space-y-8 py-12'
    >
      <Carousel
        locale={lang}
        options={{ dragFree: true }}
        className='py-1'
        renderHeader={({ scrollPrev, scrollNext }) => (
          <div className='flex items-center justify-between space-y-4 md:flex-row md:space-y-0'>
            <div>
              {section.title && <h2>{section.title}</h2>}
              {section.subtitle && (
                <p className='mt-1 text-[15px] text-gray-400'>{section.subtitle}</p>
              )}
            </div>

            <div className='flex gap-2'>
              <Button
                shape='outlined'
                theme='white'
                className='flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-white/30 p-0'
                onClick={scrollPrev}
                aria-label='Previous'
              >
                <img
                  src='/icons/common/arrow.svg'
                  className='ltr:rotate-180'
                  alt=''
                  width={24}
                  height={24}
                />
              </Button>

              <Button
                shape='outlined'
                theme='white'
                className='flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-white/30 p-0'
                onClick={scrollNext}
                aria-label='Next'
              >
                <img
                  src='/icons/common/arrow.svg'
                  className='rtl:rotate-180'
                  alt=''
                  width={24}
                  height={24}
                />
              </Button>
            </div>
          </div>
        )}
      >
        {cards.map((card, i) => {
          const isLink = !!card.href && card.href !== '#';
          const isExternal = isLink && card.href?.startsWith('http');
          const El = isLink ? Link : 'div';
          const utm = parseSectionUtm({ utm: section.utm, idx: i, id: card.id });

          return (
            <div
              className='flex max-w-xs shrink-0 basis-[75%] px-2 md:basis-1/2 xl:basis-1/4'
              key={card.id + i}
            >
              <El
                to={card.href ? `${card.href}${utm}` : '#'}
                data-testid={`home_cards_carousel_mini_item_${card.id}`}
                target={isExternal ? '_blank' : undefined}
                rel={isExternal ? 'noreferrer' : ''}
                className='border-body-light relative flex h-40 w-full items-center justify-center overflow-hidden rounded-xl border bg-[linear-gradient(0deg,transparent,var(--color))] p-4 hover:bg-[linear-gradient(0deg,transparent,var(--hover-color))]'
                style={
                  {
                    '--color': card.color ? `${card.color}30` : '',
                    '--hover-color': card.color ? `${card.color}40` : '',
                  } as React.CSSProperties
                }
                aria-label={card.title}
              >
                <img src={card.image?.url} className='h-full' alt='' />
              </El>
            </div>
          );
        })}
      </Carousel>
    </section>
  );
};

export default CardsCarouselMini;
