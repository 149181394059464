import { createContext } from 'react';
import type { ContentfulCountry, ContentfulEvent, Zone } from '@wbk/contentful/api';

export type SearchStateProps = {
  search?: string;
  searchState?: string;
  searchHistory: string[];
  showPopover: boolean;
};

type SearchContextProps = {
  events?: ContentfulEvent[];
  experiences?: ContentfulEvent[];
  shows?: ContentfulEvent[];
  restaurants?: ContentfulEvent[];
  allEventsMerged: ContentfulEvent[];
  togglePopover(state: boolean): void;
  onSearch: (value: string) => void;
  onHistoryClear: () => void;
  isLoading: boolean;
  hasResult: boolean;
  hasZoneResult: boolean;
  fetchedDone: boolean;
  zoneLoading: boolean;
  zones?: Zone[];
  countries?: ContentfulCountry[];
};

const SearchContext = createContext<SearchStateProps & SearchContextProps>({
  allEventsMerged: [],
  searchHistory: [],
  showPopover: false,
  togglePopover: () => {},
  onSearch: () => {},
  onHistoryClear: () => {},
  isLoading: false,
  fetchedDone: false,
  hasResult: false,
  hasZoneResult: false,
  zoneLoading: false,
});

export default SearchContext;
