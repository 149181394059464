import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Modal, Popover } from '@wbk/ui';
import { useWindowResize } from '@wbk/hooks';
import MobileSearchContent from '@/components/search/MobileSearchContent';
import SearchContent from '@/components/search/SearchContent';
import useSearch from '@/context/search/useSearch';

type Props = {
  title?: string;
};

const HomeSearch = ({ title }: Props) => {
  const { t } = useTranslation(['common', 'home']);
  const navigate = useNavigate();
  const { width } = useWindowResize();
  const { lang } = useParams<{ lang: Language }>();
  const { searchState, onSearch, showPopover, togglePopover } = useSearch();

  return (
    <section
      data-testid='home_search_section'
      className='container max-w-4xl space-y-4 pt-8 md:space-y-2 xl:!px-4'
    >
      <h1 className='text-center text-xl font-semibold md:text-3xl'>
        {title || t('home:search_title.full')}
      </h1>

      {width < 768 ? (
        <Modal
          fullscreen
          ignoreHistory
          unstyled
          show={showPopover}
          onClose={() => togglePopover(false)}
          className='data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-top-[45%] data-[state=open]:slide-in-from-top-[45%] fixed top-0 z-50 duration-1000'
          render={() => {
            return (
              <div className='relative mx-auto'>
                <Button
                  shape='text'
                  theme='white'
                  className='border-input-border m-px w-full justify-start border px-3 py-3.5'
                  onClick={() => togglePopover(true)}
                  data-testid='mobile-search'
                  data-location='header'
                  data-title='search'
                >
                  <img
                    className='opacity-50'
                    src='/icons/common/search.svg'
                    width={18}
                    height={18}
                    alt=''
                  />
                  <p className='shrink-0 text-sm text-gray-400'>{t('common:search_placeholder')}</p>
                </Button>
                <div className='bg-primary absolute top-1/2 flex h-9 w-9 -translate-y-1/2 items-start justify-center rounded-md ltr:right-2 rtl:left-2'>
                  <img
                    className='h-full'
                    src='/icons/common/search.svg'
                    width={18}
                    height={18}
                    alt=''
                  />
                </div>
              </div>
            );
          }}
          body={() => {
            return <MobileSearchContent />;
          }}
        />
      ) : (
        <Popover
          className='mini-scrollbar mini-scrollbar-rounded bg-body border-input-border z-20 min-h-[165px] space-y-4 overflow-y-auto rounded-lg border p-3'
          content={<SearchContent />}
        >
          <form
            className='relative z-20 mx-auto hidden w-full max-w-xl overflow-hidden py-1 md:block'
            onSubmit={(event) => {
              event.preventDefault();
              if (searchState?.trim()) {
                navigate(`/${lang}/search?q=${searchState?.trim()}`);
              }
            }}
          >
            <Input
              type='search'
              inputMode='search'
              className='h-14 w-full text-sm'
              placeholder={t('home:search_placeholder')}
              value={searchState || ''}
              data-testid='home_search_input'
              onChange={(value) => {
                if (!showPopover) {
                  togglePopover(true);
                }

                onSearch(value);
              }}
              onFocus={() => {
                togglePopover(true);
              }}
              onBlur={() => {
                togglePopover(false);
              }}
              startIcon={
                <img
                  src='/icons/common/search.svg'
                  className='absolute top-1/2 z-10 mx-2 -translate-y-1/2 transform opacity-60'
                  width={20}
                  height={20}
                  alt=''
                />
              }
            />

            <button
              type='submit'
              aria-label='Search'
              data-testid='home_search_submit_button'
              className='bg-primary absolute top-1/2 flex h-9 w-9 -translate-y-1/2 items-start justify-center rounded-md ltr:right-3 rtl:left-3'
            >
              <img
                className='h-full'
                src='/icons/common/search.svg'
                width={18}
                height={18}
                alt='Search'
              />
            </button>
          </form>
        </Popover>
      )}
    </section>
  );
};

export default HomeSearch;
