import { Suspense } from 'react';
import { WebsiteConfig } from '@wbk/contentful/api';
import { useWindowResize } from '@wbk/hooks';
import { useCycle } from 'framer-motion';
import SearchProvider from '@/context/search/provider';
import DesktopHeader from '@/components/layout/header/DesktopHeader';
import MobileHeader from '@/components/layout/header/MobileHeader';
import HideOnWebview from '../../webview/HideOnWebview';
import TopNotice from './TopNotice';

type Props = {
  type?: LayoutType;
  config: WebsiteConfig;
};

const Header = ({ config, type }: Props) => {
  const { width } = useWindowResize();
  const [isMenuOpen, toggleMenu] = useCycle(false, true);

  return (
    <HideOnWebview>
      <SearchProvider>
        {type !== 'booking' && (
          <Suspense fallback={<></>}>{!isMenuOpen && <TopNotice config={config} />}</Suspense>
        )}
        <header
          className={`border-body-lighter/50 bg-body top-0 z-40 w-full border-b ${type !== 'booking' ? 'sticky' : ''}`}
        >
          {width < 768 ? (
            <MobileHeader
              type={type}
              config={config}
              isMenuOpen={isMenuOpen}
              toggleMenu={toggleMenu}
            />
          ) : (
            <DesktopHeader type={type} config={config} />
          )}
        </header>
      </SearchProvider>
    </HideOnWebview>
  );
};

export default Header;
