import { RouteObject } from 'react-router-dom';
import ZoneDetails from '@/pages/zone/slug';
import Error404 from '@/components/error/404';

export const zonesRoutes: RouteObject[] = [
  {
    path: 'zones/:zoneSlug',
    errorElement: <Error404 />,
    element: <ZoneDetails />,
  },
];
