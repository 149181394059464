import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Carousel } from '@wbk/ui';
import { ContentfulEvent, WebSection } from '@wbk/contentful/api';
import { parseSectionUtm } from '@wbk/utils';
import { AdSlotMPU, DynamicAdSlot, useAds } from '@smc/ads';
import { resolveContentfulEventType } from '@/util/events';
import ContentfulButton from '../buttons/ContentfulButton';
import EventItem from './card';

type Props = {
  section: WebSection;
  adType?: 'mpu' | 'fluid';
};

const EventsGridCarousel = ({ section, adType = 'fluid' }: Props) => {
  const events = section.contentCollection.items as ContentfulEvent[];
  const { lang, slug } = useParams<{ lang: Language; slug?: string }>();
  const { canRenderAds, resolveExploreType, shouldRenderMpu } = useAds();

  const adTarget = resolveExploreType(resolveContentfulEventType(events?.[0]?.__typename));
  const adSlug = slug || 'home';

  if (!events?.length) {
    return null;
  }

  return (
    <section
      data-testid={`home_events_grid_carousel_${section.id}`}
      className='container space-y-8 py-12'
    >
      <Carousel
        locale={lang}
        className='p-1'
        options={{ dragFree: true }}
        renderHeader={({ scrollPrev, scrollNext }) => (
          <div className='flex items-center justify-between space-y-4 md:flex-row md:space-y-0'>
            <div>
              {section.title && <h2>{section.title}</h2>}
              {section.subtitle && (
                <p className='mt-1 text-[15px] text-gray-400'>{section.subtitle}</p>
              )}
            </div>
            <div className='flex gap-2'>
              <Button
                shape='outlined'
                theme='white'
                className='flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-white/30 p-0'
                onClick={scrollPrev}
                aria-label='Previous'
              >
                <img
                  src='/icons/common/arrow.svg'
                  className='ltr:rotate-180'
                  alt=''
                  width={24}
                  height={24}
                />
              </Button>

              <Button
                shape='outlined'
                theme='white'
                className='flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-white/30 p-0'
                onClick={scrollNext}
                aria-label='Next'
              >
                <img
                  src='/icons/common/arrow.svg'
                  className='rtl:rotate-180'
                  alt=''
                  width={24}
                  height={24}
                />
              </Button>
            </div>
          </div>
        )}
      >
        {events.map((event, i) => (
          <Fragment key={i}>
            <div className='xs:mr-3 xs:basis-1/2 relative mr-2 shrink-0 basis-[85%] md:basis-[28%] lg:basis-[22%] xl:basis-[18%]'>
              <EventItem
                event={event}
                cardType={section.itemRenderType}
                utm={parseSectionUtm({ utm: section.utm, idx: i, id: event.id })}
              />
            </div>

            {/* Ads */}
            {canRenderAds && shouldRenderMpu(events.length, i) && (
              <>
                {adType === 'fluid' ? (
                  <DynamicAdSlot
                    adUnit='fluid'
                    size='fluid'
                    slotId={`${section.id}-${i}`}
                    className='xs:mr-3 xs:basis-1/2 relative mr-2 shrink-0 basis-[85%] overflow-hidden rounded-md md:basis-[28%] lg:basis-[22%] xl:basis-[18%] [&>div>div>iframe]:h-full [&>div>div]:!h-full [&>div]:h-full'
                    targeting={{
                      // Make first letter capitalized
                      Page: adTarget.charAt(0).toUpperCase() + adTarget.slice(1),
                    }}
                  />
                ) : (
                  <AdSlotMPU
                    slotId={`${section.id}-${i}`}
                    adUnit={adSlug}
                    className='xs:mr-3 xs:basis-1/2 relative mr-2 h-full shrink-0 basis-[85%] flex-col overflow-hidden rounded-md md:basis-[28%] lg:basis-[22%] xl:basis-[18%]'
                  >
                    <div className='grow'></div>
                  </AdSlotMPU>
                )}
              </>
            )}
          </Fragment>
        ))}
      </Carousel>

      <div className='flex items-center justify-center gap-4'>
        {section.webLinksCollection.items.map((link) => (
          <ContentfulButton key={link.id} link={link} />
        ))}
      </div>
    </section>
  );
};

export default EventsGridCarousel;
