import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Input, Popover } from '@wbk/ui';
import { useWindowScroll } from '@wbk/hooks';
import useSearch from '@/context/search/useSearch';
import SearchContent from '@/components/search/SearchContent';
import useLocalization from '@/context/localization/useLocalization';

const DesktopSearch = () => {
  const { t } = useTranslation('common');
  const { y } = useWindowScroll();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { locale } = useLocalization();
  const { searchState, onSearch, showPopover, togglePopover } = useSearch();
  const isHome = pathname?.replace(locale, '') === '/';
  const show = !isHome || y > 150;

  return (
    <div className='hidden w-full max-w-md md:block'>
      <AnimatePresence key={pathname} initial={false}>
        {show && (
          <Popover
            open={showPopover}
            onOpenChange={togglePopover}
            sideOffset={8}
            align='start'
            className='mini-scrollbar mini-scrollbar-rounded bg-body border-input-border min-h-40 w-screen max-w-md space-y-4 overflow-y-auto rounded-lg border p-3'
            content={<SearchContent />}
          >
            <motion.form
              className='relative overflow-hidden p-0.5'
              initial={{ width: 0 }}
              animate={{ width: 'auto' }}
              exit={{ width: 0 }}
              onSubmit={(event) => {
                event.preventDefault();
                if (searchState?.trim()) {
                  togglePopover(false);
                  navigate(`/${locale}/search?q=${searchState?.trim()}`);
                }
              }}
            >
              <Input
                type='search'
                inputMode='search'
                className='h-9 w-full text-sm transition-all duration-500 focus:w-full'
                placeholder={t('common:search_placeholder')}
                value={searchState || ''}
                data-testid='header_nav_search_input'
                onChange={(value) => {
                  if (!showPopover) {
                    togglePopover(true);
                  }

                  onSearch(value);
                }}
                startIcon={
                  <img
                    src='/icons/common/search.svg'
                    className='absolute top-1/2 -translate-y-1/2 transform opacity-60 ltr:left-2 rtl:right-2'
                    width={24}
                    height={24}
                    alt=''
                  />
                }
              />
            </motion.form>
          </Popover>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DesktopSearch;
