import { ComponentProps, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAnalytics } from '@wbk/analytics';
import { Button } from '@wbk/ui';
import { ContentfulEvent } from '@wbk/contentful/api';
import { getUser } from '@wbk/api';
import { useQueue } from '@wbk/queue';
import ContentfulImage from '../../images/ContentfulImage';
import FreeStreamButton from '../../buttons/FreeStreamButton';

type Props = {
  event: ContentfulEvent;
  className?: string;
  href?: string;
  queueProtected?: boolean;
} & ComponentProps<typeof Button>;

const BuyTicketButton = ({ event, className, href, queueProtected, ...props }: Props) => {
  const { t } = useTranslation(['common', 'event']);
  const { isActive, shouldWaitQueue, queue } = useQueue();
  const { pathname } = useLocation();
  const cleanPath = pathname?.endsWith('/') ? pathname.slice(0, -1) : pathname;

  const isSeason = event.slug.includes('--season--');
  const updatedPath = isSeason ? cleanPath.replace(/\/events\/|\/event\//, '/seasons/') : cleanPath;

  const { generateLeadEvent } = useAnalytics();

  if (shouldWaitQueue) {
    return null;
  }

  if (event.isStreamingEvent && !event.startingPrice) {
    return <FreeStreamButton />;
  }

  if (event.eventType === 'external' && event.buttonLink) {
    return <ExternalEventButton event={event} className={className} />;
  }

  const shouldDisable = event.isComingSoon || !event.ticketingUrlSlug;
  const shouldHyDisable = event.eventType === 'external' && !event.buttonLink;

  if (shouldDisable || shouldHyDisable) {
    return event.subtitle ? (
      <div className='text-center md:text-start'>
        <p>{event.subtitle}</p>
      </div>
    ) : (
      <Button className='mx-auto min-h-12 w-full bg-gray-700 lg:mx-0' disabled>
        {event?.buttonLabel || t('common:coming_soon')}
      </Button>
    );
  }

  return (
    <Button
      queueProtected={queueProtected}
      as={Link}
      to={href || `${updatedPath}/book`}
      onClick={() => {
        generateLeadEvent({
          event_start_price: event.startingPrice,
          currency: event.currencyCode,
          title: event.title,
          slug: event.slug,
        });
      }}
      disabled={shouldWaitQueue || isActive}
      className={twMerge(
        'buy-ticket min-h-12 w-full gap-2 text-lg font-bold leading-none',
        className
      )}
      data-event-name={event.title}
      data-event-start-date={event.schedule?.openDateTime}
      data-category='main'
      {...props}
    >
      {isActive ? (
        t('common:queue_position', { number: queue?.WaitingNumber || 1 })
      ) : (
        <>
          <span>{event.buttonLabel || t('event:buy')}</span>
          {event.buttonIcon?.url && (
            <ContentfulImage src={event.buttonIcon?.url} width={16} height={16} alt={event.title} />
          )}
        </>
      )}
    </Button>
  );
};

const ExternalEventButton = ({ event, className }: Pick<Props, 'event' | 'className'>) => {
  const { t } = useTranslation('event');
  const { lang } = useParams<{ lang: Language }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  const externalLinkRef = useRef<HTMLAnchorElement>(null);
  const { generateLeadEvent } = useAnalytics();

  const handleClick = async () => {
    setIsLoading(true);
    const user = await getUser({ lang });
    setIsLoading(false);

    if (!user) {
      navigate(`/${lang}/login?redirect=${encodeURIComponent(pathname)}`);
    } else {
      handleGenerateLead();

      if (event.buttonLink) {
        window.location.href = event.buttonLink || '';
      }
    }
  };

  const handleGenerateLead = () => {
    generateLeadEvent({
      event_start_price: event.startingPrice,
      currency: event.currencyCode,
      title: event.title,
      slug: event.slug,
    });
  };

  return (
    <>
      <Button
        className={twMerge('min-h-12 w-full gap-2', className)}
        {...(event.authGaurd
          ? {
              onClick: handleClick,
              loading: isLoading,
              disabled: isLoading,
            }
          : {
              as: 'a',
              href: event.buttonLink,
              target: '_blank',
              rel: 'noopener noreferrer',
              onClick: handleGenerateLead,
            })}
      >
        <span>{event.buttonLabel || t('event:more_details')}</span>
        <img
          className={!event.buttonIcon?.url ? 'ltr:rotate-90' : ''}
          src={event.buttonIcon?.url || '/icons/common/external-arrow.svg'}
          width={16}
          height={16}
          alt={t('event:more_details').toUpperCase()}
        />
      </Button>

      {event.authGaurd && (
        <a
          href={event.buttonLink}
          target='_blank'
          rel='noopener noreferrer'
          className='hidden'
          ref={externalLinkRef}
        >
          {' '}
        </a>
      )}
    </>
  );
};

export default BuyTicketButton;
