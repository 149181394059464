import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@wbk/ui';
import EventDateTime from '@/components/events/DateTime';
import LocationIcon from '@/components/svg/Location';
import useLocalization from '@/context/localization/useLocalization';
import ContentfulImage from '../images/ContentfulImage';
import type { Zone } from '@wbk/contentful/api';

type Props = {
  zone?: Zone;
  onClick?: () => void;
  className?: string;
};

const SearchZoneItem = ({ zone, onClick, className = '' }: Props) => {
  const { locale } = useLocalization();
  const { t } = useTranslation('common');

  if (!zone) return null;

  return (
    <Button
      data-testid={`search_zone_button_${zone.slug}`}
      queueProtected
      as={Link}
      to={`/${locale}/zones/${zone.slug}`}
      onClick={onClick}
      className='flex w-full items-center justify-start gap-2 p-0.5 ltr:text-left rtl:text-right'
      theme='white'
      shape='text'
    >
      <div
        style={{ backgroundImage: `url(${zone.featuredBanner?.url})` }}
        className={`before:bg-body/80 relative h-20 w-20 rounded-md bg-cover before:absolute before:inset-0 before:z-10 before:rounded-md ${className}`}
      >
        <div className='relative z-10 flex h-full flex-col items-center justify-center gap-1'>
          <ContentfulImage
            className='mx-auto h-20 w-20 shrink-0'
            src={zone.zoneLogo?.url}
            width={80}
            height={80}
            alt={zone.title}
            fit='pad'
          />
        </div>
      </div>

      <div className='space-y-px'>
        <p className='flex items-center gap-1 text-xs text-gray-400'>
          <LocationIcon className='h-4 w-4' />
          <span>{t('common:zone')}</span>
        </p>
        <p className='text-sm font-semibold'>{zone.title}</p>
        {zone.schedule && (
          <EventDateTime schedule={zone.schedule} className='text-xs' showIcon={false} />
        )}
      </div>
    </Button>
  );
};

export default SearchZoneItem;
