import { useMemo, useRef, useState } from 'react';
import { LocationIcon } from '@wbk/svg';
import { useDeviceDetect } from '@wbk/hooks';
import { BottomSheet, Button, Input, Menu, Spinner } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { ContentfulCountry, useGetCountries } from '@wbk/contentful/api';
import { isArabic } from '@wbk/utils';
import useLocalization from '@/context/localization/useLocalization';
import useFilters from '@/context/filters/useSearch';

type Props = {
  className?: string;
};

const ExploreLocationFilter = ({ className }: Props) => {
  const { locale } = useLocalization();
  const { country, city, selectAndApply } = useFilters();
  const [search, setSearch] = useState('');
  const { isMobile } = useDeviceDetect();
  const debounceRef = useRef<NodeJS.Timeout>();
  const { t } = useTranslation('common');
  const searchLang = isArabic(search) ? 'ar-SA' : 'en-US';
  const { data, isFetching, isPending, error } = useGetCountries(
    {
      lang: search ? searchLang : locale,
      where: {
        OR: [{ title_contains: search }, { cities: { title_contains: search } }],
      },
    },
    {
      staleTime: Infinity,
    }
  );

  const handleSearch = (value: string) => {
    clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      setSearch(value);
    }, 500);
  };

  const flatList = useMemo(() => {
    const list: {
      id: string;
      value: string;
      text: string;
      country?: ContentfulCountry;
      disabled?: boolean;
    }[] = [];

    for (const country of data || []) {
      const filteredCountries = country.title.toLowerCase().includes(search.toLowerCase());
      if (filteredCountries) {
        list.push({
          id: country.id,
          value: country.countryCode,
          text: country.title,
        });
      }

      const filteredCities = country.citiesCollection.items.filter(
        (city) =>
          city.title.toLowerCase().includes(search.toLowerCase()) ||
          country.title.toLowerCase().includes(search.toLowerCase())
      );

      const cities = filteredCities.map((city) => ({
        id: city.id,
        value: city.cityCode,
        text: city.title,
        country,
      }));
      if (cities.length) {
        list.push(...cities);
      }
    }

    return list;
  }, [data, search]);

  const options = useMemo(() => {
    if (isPending) {
      return [1, 2, 3, 4].map((i) => ({
        id: i.toString(),
        value: i.toString(),
        country: i.toString(),
        label: i.toString(),
        disabled: true,
        text: (
          <div className='flex h-10 w-full items-center gap-2'>
            <LocationIcon className='h-6 w-6 shrink-0' />
            <div className='w-full space-y-2'>
              <div className='bg-text/20 h-2 max-w-[50%] animate-pulse rounded-sm' />
              <div className='bg-text/20 h-2 max-w-[60%] animate-pulse rounded-sm' />
            </div>
          </div>
        ),
      }));
    }

    if (!flatList.length) {
      return [
        {
          id: 'empty',
          value: '-',
          label: '',
          country: '',
          disabled: true,
          text: (
            <div className='w-full gap-2 space-y-2 py-2 text-center'>
              <LocationIcon className='mx-auto h-20 w-20 shrink-0 opacity-10' />
              <p className='text-text-600'>{t('common:no_results')}</p>
            </div>
          ),
        },
      ];
    }

    return flatList.map((c) => {
      const isCity = !!c.country;
      const cityWord = c.text;
      const countryWord = c?.country?.title || cityWord;

      return {
        ...c,
        label: c.text,
        text: (
          <div className='flex h-10 items-center gap-2'>
            <LocationIcon className='h-6 w-6 shrink-0' />
            <div className='text-start'>
              <div
                className='line-clamp-1'
                dangerouslySetInnerHTML={{ __html: isCity ? cityWord : countryWord }}
              />
              {!!isCity && (
                <div
                  className='text-text-700 line-clamp-1 text-xs'
                  dangerouslySetInnerHTML={{ __html: countryWord }}
                />
              )}
            </div>
          </div>
        ),
      };
    });
  }, [flatList, isPending, t]);

  const selectedOption = useMemo(() => {
    if (city) {
      return options.find((option) => option.value?.toLowerCase() === city?.toLowerCase());
    }
    return options.find((option) => option.value?.toLowerCase() === country?.toLowerCase());
  }, [city, country, options]);

  if (isMobile) {
    return (
      <BottomSheet
        fullscreen
        ignoreHistory
        sectionClassName='px-4 pb-8'
        render={({ open }) => (
          <Button
            theme='white'
            shape='outlined'
            className={twMerge('border-text/40 w-full justify-start border px-2', className)}
            onClick={() => {
              // Clear search on open
              setSearch('');
              open();
            }}
          >
            <LocationIcon className='h-6 w-6' />
            <div className='text-start'>
              <p className='text-text-700 text-xs'>{t('common:destination')}</p>
              <p className='line-clamp-1'>{selectedOption?.label || t('common:everywhere')}</p>
            </div>
          </Button>
        )}
        stickyHeader={() => (
          <div className='px-4 pb-2'>
            <Input
              onChange={handleSearch}
              placeholder={t('common:search')}
              error={error?.message}
              autoFocus
              endIcon={
                isFetching ? (
                  <div className='mt-3 flex items-center justify-end px-2'>
                    <Spinner className='h-4 w-4' />
                  </div>
                ) : null
              }
            />
          </div>
        )}
        body={({ close }) => (
          <ul className='divide-text/10 divide-y'>
            {options.map((option) => (
              <li key={option.id} className='py-1'>
                {option.disabled ? (
                  option.text
                ) : (
                  <Button
                    theme='white'
                    shape='text'
                    className='w-full justify-start px-0 !ring-0'
                    onClick={() => {
                      close();
                      const country = option?.country as ContentfulCountry;
                      const isCity = !!country;
                      selectAndApply([
                        { key: 'city', value: isCity ? option.value : null },
                        { key: 'country', value: country?.countryCode || option.value },
                      ]);
                    }}
                  >
                    {option.text}
                  </Button>
                )}
              </li>
            ))}
          </ul>
        )}
      />
    );
  }

  return (
    <Menu
      key={city || country}
      className={twMerge('border-text/20 w-full justify-start border px-2', className)}
      contentProps={{ className: 'w-[330px]' }}
      optionsClassName='[&>div]:px-1'
      placeholder={t('common:search')}
      value={city || country || ''}
      onChange={(option) => {
        const country = (option as { country?: ContentfulCountry })?.country;
        const isCity = !!country;
        selectAndApply([
          { key: 'city', value: isCity ? option.value : null },
          { key: 'country', value: country?.countryCode || option.value },
        ]);
      }}
      onOpenChange={(open) => {
        // On close clear search
        if (!open) {
          setSearch('');
        }
      }}
      renderSelected={(selected) => {
        const label = (selected as { label?: string })?.label || t('common:everywhere');
        return (
          <div className='flex items-center gap-2'>
            <LocationIcon className='h-6 w-6' />
            <div className='text-start'>
              <p className='text-text-700 text-xs'>{t('common:destination')}</p>
              <p className='line-clamp-1'>{label}</p>
            </div>
          </div>
        );
      }}
      onSearch={handleSearch}
      options={options}
      error={error?.message}
      searchLoading={isFetching}
    />
  );
};

export default ExploreLocationFilter;
