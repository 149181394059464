import { useTranslation } from 'react-i18next';
import { Button, ImageWithFallback } from '@wbk/ui';
import { Product } from '@wbk/api';
import { useDeviceDetect } from '@wbk/hooks';
import { CurrencyPriceLabel } from '@wbk/currency';

type Props = {
  product: Product;
  showBuyButton?: boolean;
};

const ShopProductItem = ({ product, showBuyButton = true }: Props) => {
  const { t } = useTranslation(['common']);
  const { isWebView } = useDeviceDetect();

  return (
    <a
      href={product.link}
      target={isWebView ? '_self' : '_blank'}
      className='flex h-full flex-col overflow-hidden rounded-lg shadow-[0px_2px_4px_-1px_#0000000F,0px_4px_6px_-1px_#0000001A]'
    >
      <ImageWithFallback
        src={product.featured_image_url}
        alt={product.title.rendered}
        width={300}
        height={300}
      />

      <div className='flex grow flex-col gap-1.5 bg-zinc-900 px-2 py-3'>
        <div
          className='line-clamp-2 grow text-sm font-semibold text-gray-100'
          dangerouslySetInnerHTML={{ __html: product.title.rendered }}
        />
        <CurrencyPriceLabel
          originalPrice={parseFloat(product._regular_price) || parseFloat(product._price) || 0}
          discountPrice={parseFloat(product._sale_price)}
          currency='SAR'
          className='text-xs'
        />
        {showBuyButton && (
          <Button className='!mt-auto inline-block h-7 self-start py-0 text-xs' tabIndex={-1}>
            {t('common:shop.buy_now')}
          </Button>
        )}
      </div>
    </a>
  );
};

export default ShopProductItem;
