import { twMerge } from 'tailwind-merge';
import { motion } from 'framer-motion';
import { ContentfulEvent } from '@wbk/contentful/api';
import { useDeviceDetect, useWindowScroll } from '@wbk/hooks';
import { useQueue } from '@wbk/queue';
import { useTranslation } from 'react-i18next';
import EventStartPrice from '../StartPrice';
import NotifyButton from './NotifyButton';
import BuyTicketButton from './BuyButton';

type Props = {
  event: ContentfulEvent;
  className?: string;
  offset?: number;
} & React.ComponentProps<typeof BuyTicketButton>;

const MobileStickyBuy = ({ event, className, offset = 800, ...props }: Props) => {
  const { t } = useTranslation('event');
  const { isActive, shouldWaitQueue } = useQueue();
  const { y } = useWindowScroll();
  const { isWebView } = useDeviceDetect();
  const notifyMe = !!event.eventType?.includes('offline');

  if (isActive || shouldWaitQueue) return null;

  return (
    <>
      <style>{`
        @media (max-width: 1023px) {
          #root > footer {margin-bottom:80px;}
        }
      `}</style>

      <motion.div
        initial={{ y: 200 }}
        animate={{ y: y > offset ? 0 : 200 }}
        transition={{ type: 'tween' }}
        className={`bg-body-light fixed bottom-0 left-0 z-30 min-h-28 w-full space-y-2 rounded-t-lg border-t border-white/5 data-[notify=true]:min-h-20 lg:hidden ${isWebView ? 'px-4 pb-6 pt-2' : 'safe-bottom px-4 py-2'}`}
        data-testid='buy-tickets-cta-mobile'
        data-notify={notifyMe}
      >
        {notifyMe ? (
          <NotifyButton
            event={event}
            className='py-2 text-base'
            type={event.__typename === 'Experience' ? 'experience' : 'event'}
          />
        ) : (
          <>
            <p className='text-lg font-semibold'>{t('event:book_spot')}</p>
            <div className='flex w-full items-center justify-between gap-4'>
              <EventStartPrice event={event} className='min-w-28 [&>p]:text-xs' />
              <BuyTicketButton
                event={event}
                className={twMerge('max-w-xs text-base', className)}
                {...props}
              />
            </div>
          </>
        )}
      </motion.div>
    </>
  );
};

export default MobileStickyBuy;
