import { useParams } from 'react-router-dom';
import { Button, Carousel } from '@wbk/ui';
import { ContentfulEvent, WebSection } from '@wbk/contentful/api';
import { parseSectionUtm } from '@wbk/utils';
import EventItem from './card';

type Props = {
  section: WebSection;
};

const EventsNumberedCarousel = ({ section }: Props) => {
  const events = section.contentCollection.items as ContentfulEvent[];
  const { lang } = useParams<{ lang: Language; slug?: string }>();

  if (!events?.length) {
    return null;
  }

  return (
    <section
      data-testid={`home_events_numbered_carousel_${section.id}`}
      className='container space-y-8 py-12'
    >
      <Carousel
        locale={lang}
        options={{ dragFree: true }}
        className='py-0.5'
        renderHeader={({ scrollPrev, scrollNext }) => (
          <div className='flex items-center justify-between space-y-4 md:flex-row md:space-y-0'>
            <div>
              {section.title && <h2>{section.title}</h2>}
              {section.subtitle && (
                <p className='mt-1 text-[15px] text-gray-400'>{section.subtitle}</p>
              )}
            </div>

            <div className='flex gap-2'>
              <Button
                shape='outlined'
                theme='white'
                className='flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-white/30 p-0'
                onClick={scrollPrev}
                aria-label='Previous'
              >
                <img
                  src='/icons/common/arrow.svg'
                  className='ltr:rotate-180'
                  alt=''
                  width={24}
                  height={24}
                />
              </Button>

              <Button
                shape='outlined'
                theme='white'
                className='flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-white/30 p-0'
                onClick={scrollNext}
                aria-label='Next'
              >
                <img
                  src='/icons/common/arrow.svg'
                  className='rtl:rotate-180'
                  alt=''
                  width={24}
                  height={24}
                />
              </Button>
            </div>
          </div>
        )}
      >
        {events.map((event, i) => (
          <div
            className='relative mr-1 flex shrink-0 basis-full sm:basis-3/4 md:basis-3/5 lg:basis-1/2 xl:basis-2/5'
            key={i}
          >
            <div className='hidden self-end text-[200px] font-semibold text-white/10 sm:block ltr:-mr-4 rtl:-ml-4'>
              {i + 1}
            </div>

            <EventItem
              event={event}
              cardType={section.itemRenderType}
              utm={parseSectionUtm({ utm: section.utm, idx: i, id: event.id })}
            />
          </div>
        ))}
      </Carousel>
    </section>
  );
};

export default EventsNumberedCarousel;
