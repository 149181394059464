import { ContentfulEvent } from '@wbk/contentful/api';
import { calculareDiscountPercentage } from '@wbk/utils';
import { useTranslation } from 'react-i18next';
import { useCurrency } from '@wbk/currency';

type Props = {
  event: Pick<
    ContentfulEvent,
    | 'discountedPrice'
    | 'startingPrice'
    | 'isComingSoon'
    | 'startingPrice'
    | 'currencyCode'
    | 'startingPrice'
  >;
};

const EventItemPrice = ({ event }: Props) => {
  const { t } = useTranslation(['event']);
  const discount = event.discountedPrice || 0;
  const price = discount || event.startingPrice;
  const { exchangePrice, symbol } = useCurrency();

  if (event.isComingSoon) {
    return <p className='text-xs text-gray-400'>{t('common:coming_soon')}</p>;
  }

  if (price <= 0) {
    return <p className='text-success font-semibold'>{t('event:free')}</p>;
  }

  return (
    <div>
      {discount > 0 && (
        <div className='flex items-center gap-1 text-xs leading-none'>
          <p className='text-gray-500 line-through'>
            {`${exchangePrice(event.startingPrice, event.currencyCode)} ${symbol}`}
          </p>
          <p className='bg-success-light/15 text-success-light rounded-sm px-1 py-0.5'>
            {`${calculareDiscountPercentage(event.startingPrice, discount)}%`}
          </p>
        </div>
      )}
      <div className='flex items-center gap-1'>
        <p className='text-sm text-gray-400'>{t('event:starts_from')}</p>
        <p className='font-semibold'>{`${exchangePrice(price, event.currencyCode)} ${symbol}`}</p>
      </div>
    </div>
  );
};

export default EventItemPrice;
