import { RouteObject, createBrowserRouter, redirect } from 'react-router-dom';
import { handleInvalidLocalPath } from '@wbk/utils';
import { defaultLang, supportedLngs } from '@/i18n/config';

import Home from '@/pages/Home';
import Error404 from '@/components/error/404';
import Error500 from '@/components/error/500';
import AppLayout from '../App';

import { exploreRoutes } from './explore';
import { eventDetailRoutes } from './events';
import { eventBookingRoutes } from './booking';
import { paymentRoutes } from './payment';
import { authRoutes } from './auth';
import { profileRoutes } from './profile';
import { infoRoutes } from './info';
import { customPagesRoutes } from './customPages';
import { geaRoutes } from './gea';
import { zonesRoutes } from './zone';
import { searchRoutes } from './search';
import { referralRoutes } from './referral';
import { organizationRoutes } from './organizations';
import { subscriptionRoutes } from './subscriptions';
// import { flightsBookingRoutes, flightsRoutes } from './flights';

export const router = createBrowserRouter([
  {
    path: '/:lang',
    element: <AppLayout />,
    loader: ({ params, request }) => {
      const segments = Object.values(params || {}) || [];
      const firstSegment = segments?.[0] as Language;
      const isValidLang = supportedLngs.includes(firstSegment);

      if (!isValidLang) {
        const goto = handleInvalidLocalPath({
          url: request.url,
          path: segments.join('/'),
          supportedLngs,
          defaultLang,
        });
        return redirect(goto);
      }
      return null;
    },
    children: (
      [
        {
          path: '',
          element: <Home />,
          errorElement: <Error500 />,
        },
      ] as RouteObject[]
    ).concat(
      infoRoutes,
      exploreRoutes,
      eventDetailRoutes,
      organizationRoutes,
      paymentRoutes,
      profileRoutes,
      zonesRoutes,
      searchRoutes,
      referralRoutes,
      subscriptionRoutes,
      // flightsRoutes,
      // Must be last
      customPagesRoutes
    ),
  },
  {
    path: '/:lang',
    element: <AppLayout type='booking' />,
    children: eventBookingRoutes, //.concat(flightsBookingRoutes),
  },
  {
    path: '/:lang',
    element: <AppLayout type='auth' />,
    children: authRoutes,
  },
  ...geaRoutes,
  {
    path: '/',
    loader: ({ request }) => {
      const goto = handleInvalidLocalPath({
        url: request.url,
        path: '',
        supportedLngs,
        defaultLang,
      });
      return redirect(goto);
    },
  },
  {
    path: '*',
    element: <AppLayout />,
    loader: ({ params, request }) => {
      const path = params['*'];
      const firstSegment = (path?.split('/')?.[0] || '') as Language;
      const isValidLang = supportedLngs.includes(firstSegment);
      if (!isValidLang) {
        const goto = handleInvalidLocalPath({
          url: request.url,
          path: path || '',
          supportedLngs,
          defaultLang,
        });
        return redirect(goto);
      }
      return null;
    },
    children: [
      {
        path: '*',
        element: <Error404 />,
      },
    ],
  },
]);
