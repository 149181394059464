import { ContentfulEvent, WebCard, useSections } from '@wbk/contentful/api';
import { Button, Carousel } from '@wbk/ui';
import { Link } from 'react-router-dom';
import { parseSectionUtm } from '@wbk/utils';
import { resolveContentfulEventType } from '@/util/events';
import { POSTER_3X1_FADED } from '@/constants/fallbackLogo';
import useLocalization from '@/context/localization/useLocalization';
import ContentfulImage from '../images/ContentfulImage';
import EventItemType from '../events/card/Type';
import EventItemPrice from '../events/card/Price';

const SearchFeaturedEvents = () => {
  const { locale } = useLocalization();
  const { data, isLoading } = useSections({
    lang: locale,
    limit: 1,
    where: { id: 'webook-search-featured' },
  });

  if (isLoading) {
    return (
      <div className='space-y-4 py-2'>
        <div className='bg-body-lighter h-7 w-1/3 animate-pulse rounded-md' />

        <div className='flex gap-2 overflow-hidden'>
          <div className='aspect-[3/1.4] h-full w-full max-w-[85%] shrink-0 overflow-hidden rounded-md'>
            <img className='h-full object-cover' src={POSTER_3X1_FADED} alt='poster' />
          </div>
          <div className='aspect-[3/1.4] h-full w-full max-w-[85%] shrink-0 overflow-hidden rounded-md'>
            <img className='h-full object-cover' src={POSTER_3X1_FADED} alt='poster' />
          </div>
        </div>
      </div>
    );
  }
  const section = data?.items?.[0];
  const events = section?.contentCollection?.items as ContentfulEvent[];

  if (!section || !events?.length) {
    return null;
  }

  return (
    <Carousel
      locale={locale}
      className='p-1'
      options={{ dragFree: true }}
      renderHeader={({ scrollPrev, scrollNext }) => (
        <div className='flex items-center justify-between space-y-2 md:flex-row md:space-y-0'>
          {section.title && <p className='text-xl font-semibold'>{section.title}</p>}
          <div className='flex gap-2'>
            <Button
              data-testid='header_search_featured_events_previous_button'
              shape='outlined'
              theme='white'
              className='flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-white/30 p-0'
              onClick={scrollPrev}
              aria-label='Previous'
            >
              <img
                src='/icons/common/arrow.svg'
                className='ltr:rotate-180'
                alt=''
                width={24}
                height={24}
              />
            </Button>
            <Button
              data-testid='header_search_featured_events_next_button'
              shape='outlined'
              theme='white'
              className='flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-white/30 p-0'
              onClick={scrollNext}
              aria-label='Next'
            >
              <img
                src='/icons/common/arrow.svg'
                className='rtl:rotate-180'
                alt=''
                width={24}
                height={24}
              />
            </Button>
          </div>
        </div>
      )}
    >
      {isLoading
        ? Array.from({ length: 5 }).map((_, index) => (
            <div
              key={index}
              className='relative flex shrink-0 basis-5/6 md:basis-9/12 ltr:mr-3 rtl:ml-3'
            >
              <div className='group relative h-full w-full animate-pulse gap-0 bg-transparent p-px ring-offset-white focus:ring-1'>
                <div className='flex h-full w-full flex-col items-start overflow-hidden rounded-md'>
                  <div className='relative aspect-[2/1] h-full w-full overflow-hidden rounded-md'>
                    <img className='h-full object-cover' src={POSTER_3X1_FADED} alt='poster' />
                  </div>
                  <div />
                </div>
              </div>
            </div>
          ))
        : events.map((event, index) => {
            const isCard = (event.__typename as unknown as string) === 'WebCard';
            const utm = parseSectionUtm({ utm: section.utm, idx: index, id: event.id });
            if (isCard) {
              const card = event as unknown as WebCard;
              return (
                <SlideItem
                  key={index}
                  href={card.href + utm}
                  title={card.title}
                  image31={card.image31?.url || card.image?.url}
                  external={card?.href?.startsWith('http')}
                  event={event}
                />
              );
            }
            return (
              <SlideItem
                key={index}
                href={`/${locale}/${resolveContentfulEventType(event.__typename)}/${event.slug}${utm}`}
                title={event.title}
                image31={event.image31?.url}
                event={event}
              />
            );
          })}
    </Carousel>
  );
};

type SlideItemType = {
  key?: number;
  href?: string;
  image31: string;
  title: string;
  external?: boolean;
  event: ContentfulEvent;
};
const SlideItem = ({ title, href, image31, external, key, event }: SlideItemType) => {
  return (
    <div key={key} className='relative flex shrink-0 basis-5/6 md:basis-9/12 ltr:mr-3 rtl:ml-3'>
      <Button
        data-testid={`header_search_featured_event_${event.slug}`}
        theme='transparent'
        as={Link}
        queueProtected
        to={href}
        target={external ? '_blank' : undefined}
        className='group relative h-full w-full gap-0 bg-transparent p-px ring-offset-white focus:ring-1'
      >
        <div className='flex h-full w-full flex-col items-start overflow-hidden rounded-md'>
          <div className={'relative aspect-[2/1] h-full w-full overflow-hidden rounded-md'}>
            <ContentfulImage
              className='h-full object-cover transition duration-300 group-hover:scale-[1.02] group-focus:scale-[1.02]'
              src={image31}
              width='100%'
              height='100%'
              ratio='3x1'
              alt={title}
              fit='thumb'
              placeholder
            />
          </div>
          <div className='absolute inset-0 scale-[1.02] bg-gradient-to-b from-transparent via-black/50 to-black/95 to-85%' />
          <div className='absolute inset-0 z-20 flex flex-col justify-between p-2'>
            <div />
            <div className='text-text space-y-0.5 pt-2 text-start'>
              <EventItemType event={event} />
              <p className='line-clamp-2'>{title}</p>
              {event.startingPrice && event.startingPrice > 0 && <EventItemPrice event={event} />}
            </div>
          </div>
        </div>
      </Button>
    </div>
  );
};

export default SearchFeaturedEvents;
