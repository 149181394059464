import { useGetCategories } from '@wbk/contentful/api';
import { useTranslation } from 'react-i18next';
import { Button, Carousel, ImageWithFallback } from '@wbk/ui';
import { Link } from 'react-router-dom';
import useLocalization from '@/context/localization/useLocalization';
import useSearch from '@/context/search/useSearch';

const SearchCategories = () => {
  const { locale, countryCode } = useLocalization();
  const { togglePopover } = useSearch();
  const { t } = useTranslation('common');
  const { data, isLoading } = useGetCategories(
    {
      lang: locale,
      where: { childrenCollection_exists: true, country: { countryCode } },
    },
    {
      enabled: !!countryCode,
    }
  );

  if (isLoading) {
    return (
      <div className='space-y-4 py-2'>
        <div className='bg-body-lighter h-7 w-1/3 animate-pulse rounded-md' />

        <div className='flex gap-2 overflow-hidden md:hidden'>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
            <div
              key={i}
              className='bg-body-lighter h-[88px] shrink-0 basis-1/3 animate-pulse rounded-md sm:basis-1/4'
            />
          ))}
        </div>

        <ul className='hidden grid-cols-3 gap-2 md:grid lg:grid-cols-4'>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
            <li key={i} className='bg-body-lighter h-[88px] animate-pulse rounded-md' />
          ))}
        </ul>
      </div>
    );
  }

  if (!data?.length) {
    return null;
  }

  const categories = data.map((category) => (
    <div key={category.id} className='shrink-0 basis-[30%] space-y-1 sm:basis-[22%]'>
      <Button
        theme='transparent'
        as={Link}
        to={`/${locale}/explore?category=${category.slug}`}
        onClick={() => togglePopover(false)}
        className='bg-body-lighter hover:bg-body-lighter/80 w-full flex-col items-start rounded-md px-2 py-3 text-start text-sm focus:ring-white'
      >
        <ImageWithFallback
          src={category.icon?.url}
          className='h-10 w-10'
          width={40}
          height={40}
          alt={category.title}
        />
        <p className='line-clamp-1'>{category.title}</p>
      </Button>
    </div>
  ));

  return (
    <div className='space-y-3'>
      <p className='text-xl font-semibold'>{t('common:explore_things')}</p>
      <Carousel
        locale={locale}
        options={{ dragFree: true }}
        className='py-1 md:hidden'
        containerClassName='gap-2'
      >
        {categories}
      </Carousel>
      <div className='hidden grid-cols-3 gap-2 py-1 md:grid lg:grid-cols-4'>{categories}</div>
    </div>
  );
};

export default SearchCategories;
