import { Link, useParams } from 'react-router-dom';
import { parseSectionUtm } from '@wbk/utils';
import { Button, Carousel } from '@wbk/ui';
import ContentfulImage from '../images/ContentfulImage';
import type { WebCard, WebSection } from '@wbk/contentful/api';

type Props = {
  section: WebSection;
};

const RectangularCardsCarousel = ({ section }: Props) => {
  const cards = section.contentCollection.items as WebCard[];
  const { lang } = useParams<{ lang: Language }>();

  if (!cards?.length) return null;

  return (
    <section
      data-testid={`home_cards_carousel_${section.id}`}
      className='container space-y-8 py-12'
    >
      <Carousel
        locale={lang}
        options={{ dragFree: true }}
        className='py-1'
        renderHeader={({ scrollPrev, scrollNext }) => (
          <div className='flex items-center justify-between space-y-4 md:flex-row md:space-y-0'>
            <div>
              {section.title && <h2>{section.title}</h2>}
              {section.subtitle && (
                <p className='mt-1 text-[15px] text-gray-400'>{section.subtitle}</p>
              )}
            </div>

            <div className='flex gap-2'>
              <Button
                shape='outlined'
                theme='white'
                className='flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-white/30 p-0'
                onClick={scrollPrev}
                aria-label='Previous'
              >
                <img
                  src='/icons/common/arrow.svg'
                  className='ltr:rotate-180'
                  alt=''
                  width={24}
                  height={24}
                />
              </Button>

              <Button
                shape='outlined'
                theme='white'
                className='flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-white/30 p-0'
                onClick={scrollNext}
                aria-label='Next'
              >
                <img
                  src='/icons/common/arrow.svg'
                  className='rtl:rotate-180'
                  alt=''
                  width={24}
                  height={24}
                />
              </Button>
            </div>
          </div>
        )}
      >
        {cards.map((card, i) => {
          const isLink = !!card.href && card.href !== '#';
          const isExternal = isLink && card.href?.startsWith('http');
          const El = isLink ? Link : 'div';
          const utm = parseSectionUtm({ utm: section.utm, idx: i, id: card.id });

          return (
            <div
              className='relative mr-4 shrink-0 basis-[66%] rounded-lg sm:basis-[40%] md:basis-[28%] lg:basis-1/5 xl:basis-[16%]'
              key={i}
            >
              <El
                data-testid={`home_cards_carousel_item_${card.id}`}
                className='group'
                to={card.href ? `${card.href}${utm}` : '#'}
                target={isExternal ? '_blank' : undefined}
                rel='noreferrer'
              >
                <div className='aspect-[2/3] rounded-lg'>
                  <div className='absolute inset-0 z-10 scale-105 bg-gradient-to-b from-black/5 via-black/10 to-black/95 to-90%' />
                  <div className='relative h-full overflow-hidden rounded-lg bg-cover transition duration-500'>
                    <ContentfulImage
                      className='aspect-1 absolute h-full object-cover transition duration-300 group-hover:scale-105 group-focus:scale-105'
                      src={card.image.url}
                      width={600}
                      height={900}
                      fit='thumb'
                      ratio='1x1'
                      alt={card.title}
                      placeholder
                    />

                    <div className='flex h-full w-full flex-col justify-between overflow-hidden'>
                      <div />
                      <div className='relative z-50 flex items-end justify-between p-4'>
                        <div className='space-y-2'>
                          <p className='text-xl'>{card.title}</p>
                          {card.subtitle && <p className='text-sm'>{card.subtitle}</p>}
                        </div>
                        {card.icon && (
                          <div className='shrink-0'>
                            <ContentfulImage
                              src={card.icon.url}
                              width={30}
                              height={31}
                              alt=''
                              fit='pad'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </El>
            </div>
          );
        })}
      </Carousel>
    </section>
  );
};

export default RectangularCardsCarousel;
