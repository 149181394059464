import { Link } from 'react-router-dom';
import { parseSectionUtm } from '@wbk/utils';
import { Button, Carousel } from '@wbk/ui';
import useLocalization from '@/context/localization/useLocalization';
import ContentfulImage from '../images/ContentfulImage';
import type { ContentfulCategory, WebSection } from '@wbk/contentful/api';

type Props = {
  section: WebSection;
};

const CategoryCarousel = ({ section }: Props) => {
  const { locale } = useLocalization();
  const categories = section.contentCollection.items as ContentfulCategory[];

  if (!categories?.length) return null;

  return (
    <section
      data-testid={`home_category_carousel_${section.id}`}
      className='container space-y-8 py-12'
    >
      <h2 className='text-2xl font-semibold text-gray-100'>{section.title}</h2>
      <Carousel
        locale={locale}
        className='p-0.5'
        options={{ dragFree: true, active: categories.length > 2 }}
      >
        {categories.map((category, idx) => {
          const utm = parseSectionUtm({ utm: section.utm, idx, id: category.id });
          const safeUtm = utm?.replace('?', '&');
          return (
            <Button
              theme='white'
              shape='text'
              as={Link}
              href={`/${locale}/explore?category=${category.slug}${safeUtm}`}
              className='block shrink-0 basis-32 p-px ltr:mr-4 rtl:ml-4'
              key={idx}
            >
              <div className='bg-body-light relative flex h-32 w-32 items-center justify-center overflow-hidden rounded-full'>
                {category.background?.url ? (
                  <ContentfulImage
                    className='absolute h-full w-full object-cover'
                    src={category.background.url}
                    alt={category.title}
                  />
                ) : (
                  <ContentfulImage
                    className='h-20 w-20'
                    src={category.icon.url}
                    width={20}
                    height={20}
                    alt={category.title}
                    format={null}
                  />
                )}
              </div>
              <p>{category.title}</p>
            </Button>
          );
        })}
      </Carousel>
    </section>
  );
};

export default CategoryCarousel;
