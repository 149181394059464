import { Controller, useFormContext } from 'react-hook-form';
import { RadioGroup } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { useRequestDisabilityTickets } from '@wbk/api/hooks';
import type { AccessibilityFormValues } from './types';

const TYPES = [
  'wheelchair_user',
  'easy_access_standard',
  // 'companion_tickets',
  'easy_access_amenity',
];

const AccessibilityTicketForm = () => {
  const { t } = useTranslation(['common', 'event']);
  const { handleSubmit, watch, control, setValue } = useFormContext<AccessibilityFormValues>();
  const { mutateAsync: submit, error } = useRequestDisabilityTickets();
  const mode = watch('mode');

  const onSubmit = async (data: AccessibilityFormValues) => {
    try {
      setValue('loading', true);
      const body = new FormData();
      const exclude = ['mode', 'direction', 'success', 'loading'];
      for (const key in data) {
        if (!exclude.includes(key)) {
          const value = data[key as keyof AccessibilityFormValues];
          body.append(key, value as string);
        }
      }
      await submit({ body });
      setValue('success', true);
    } finally {
      setValue('loading', false);
    }
  };

  return (
    <form className='space-y-4' id={`accessibility_step_${mode}`} onSubmit={handleSubmit(onSubmit)}>
      {error?.message && <p className='text-error py-2'>{error.message}</p>}
      <Controller
        name='ticket_type'
        control={control}
        defaultValue=''
        rules={{ required: t('common:validation.required') }}
        render={({ field, fieldState: { error } }) => (
          <RadioGroup
            {...field}
            label={
              <span className={error ? 'text-error' : ''}>
                {t('event:accessibility.ticket_types')}
                {!!error && <span className='text-error text-sm'>{` - ${error?.message}`}</span>}
              </span>
            }
            className='items-start [&>button]:mt-1'
            options={TYPES.map((type) => {
              return {
                text: (
                  <div className='space-y-1'>
                    <p>{t(`event:accessibility.${type}`)}</p>
                    <p className='text-text-800 text-sm'>{t(`event:accessibility.${type}_desc`)}</p>
                  </div>
                ),
                value: type,
              };
            })}
            error={error?.message}
          />
        )}
      />
    </form>
  );
};

export default AccessibilityTicketForm;
