import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel } from '@wbk/ui';
import ContentfulImage from '@/components/images/ContentfulImage';
import type { ContentfulImage as ContentfulImageType } from '@wbk/contentful/api';

type Image = Pick<ContentfulImageType, 'url' | 'title'>;

type Props = {
  poster: Image;
  carousel?: Image[];
  autoPlay?: boolean;
  className?: string;
};

const GalleryWithThumbs = ({ poster, carousel, className }: Props) => {
  const { lang } = useParams<{ lang: string }>();

  const list = useMemo(() => {
    if (carousel?.length) {
      return carousel;
    }

    return [poster];
  }, [carousel, poster]);

  return (
    <Carousel
      locale={lang}
      rootClassName={className}
      className='aspect-1 mx-auto max-w-[600px]'
      thumbClassName='mx-auto max-w-[600px] pt-2'
      thumbs={({ scrollTo, selectedIndex }) => (
        <div className='flex gap-2'>
          {list.map((image, i) => (
            <button
              key={image.url + i}
              className='aspect-1 relative shrink-0 overflow-hidden rounded-lg'
              aria-label={image.title}
              onClick={() => scrollTo(i)}
            >
              {i !== selectedIndex && <div className='absolute inset-0 z-10 bg-black/70' />}
              <ContentfulImage
                className='relative block h-20 w-20 object-cover'
                src={image.url}
                alt={image.title}
              />
            </button>
          ))}
        </div>
      )}
    >
      {list.map((image, i) => (
        <div className='aspect-1 mr-1 shrink-0 basis-full' key={image.url + i}>
          <figure className='h-full w-full'>
            <ContentfulImage
              className='h-full w-full overflow-hidden rounded-lg object-cover'
              src={image.url}
              alt={image.title}
              placeholder
            />
          </figure>
        </div>
      ))}
    </Carousel>
  );
};

export default GalleryWithThumbs;
