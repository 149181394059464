import { Link, useParams } from 'react-router-dom';
import { WebsiteConfig } from '@wbk/contentful/api';
import SideMenu from '@/components/layout/header/SideMenu';
import LanguageCurrencySwitch from '@/components/common/LanguageCurrencySwitch';
import MobileSearch from '@/components/search/MobileSearch';

type Props = {
  type?: LayoutType;
  config: WebsiteConfig;
  isMenuOpen: boolean;
  toggleMenu: () => void;
};

const MobileHeader = ({ type, config, isMenuOpen, toggleMenu }: Props) => {
  const { lang } = useParams<{ lang: Language }>();
  return (
    <nav className='flex items-center justify-between gap-2 px-3 py-1.5'>
      {type !== 'booking' && (
        <SideMenu config={config} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      )}
      <Link
        data-testid='header_mobile_logo'
        to={`/${lang || ''}`}
        className='logo-image relative z-20 flex items-center justify-center hover:opacity-90'
        data-location='header'
        aria-label='webook.com Logo'
      >
        <img className='w-full min-w-[64px] max-w-[90px]' src={config.logo.url} alt='' />
      </Link>

      {type !== 'booking' && <MobileSearch />}
      <div className='z-50 flex [&>button]:px-1 [&>button]:text-xs' role='menu'>
        <LanguageCurrencySwitch />
      </div>
    </nav>
  );
};

export default MobileHeader;
