import { Restaurant } from '@wbk/contentful/api';
import ShareModal from '../common/ShareModal';
import ContentfulImage from '../images/ContentfulImage';
import MobileStickyBuy from '../events/buttons/MobileStickyBuy';

type Props = {
  event: Restaurant;
};

const RestaurantDetails = ({ event }: Props) => {
  const tags = event.tags?.length && (
    <div className='flex flex-wrap items-center text-xs text-gray-400'>
      {event.tags.map((tag, i) => (
        <div key={i} className='flex'>
          <span className='shrink-0'>{tag}</span>
          {i !== event.tags.length - 1 && <span className='mx-1 text-gray-400'>•</span>}
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div className='mb-2 md:hidden'>{tags}</div>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-4'>
          <ContentfulImage
            className='hidden rounded-md object-contain object-center md:block'
            ratio='1x1'
            src={event?.logo?.url}
            width={100}
            height={100}
            alt={event.title}
            placeholder
          />

          <ContentfulImage
            className='rounded-md object-contain object-center md:hidden'
            ratio='1x1'
            src={event?.logo?.url}
            width={58}
            height={58}
            alt={event.title}
            placeholder
          />

          <div className='line-clamp-2'>
            <div className='mb-2 hidden md:block'>{tags}</div>
            <h1 className='mb-2 text-2xl font-semibold md:text-4xl'>{event.title}</h1>
            {event.location?.address && <p className='text-sm'>{event.location.address}</p>}
          </div>
        </div>
        <div className='hidden md:block'>
          <ShareModal key='desktop' share_description={event?.title} share_title={event?.title} />
        </div>
      </div>

      {/* Mobile sticky */}
      <MobileStickyBuy event={event} />
    </>
  );
};

export default RestaurantDetails;
