import { useWebsiteLocale } from '@wbk/hooks';
import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';
import { Link } from 'react-router-dom';
import ShareModal from '../common/ShareModal';
import StreamIncludedBar from '../stream/StreamIncludedBar';
import ZoneIncludedBar from '../zone/ZoneIncludedBar';
import ContentfulImage from '../images/ContentfulImage';
import EventLocation from './card/Location';
import MobileStickyBuy from './buttons/MobileStickyBuy';
import type { ContentfulEvent, Experience } from '@wbk/contentful/api';

type Props = {
  event: ContentfulEvent;
};

const EventTitleDetails = ({ event }: Props) => {
  const { shortLang } = useWebsiteLocale();
  const { t } = useTranslation('event');
  const hyProps = (event as Experience).hyProperties;
  const city = hyProps
    ? hyProps[`city_${shortLang}` as 'city'] || hyProps.city
    : event.location?.city;

  return (
    <>
      <div className='flex flex-wrap items-center gap-1 text-sm'>
        <EventLocation location={{ ...event.location, city }} />
        {!hyProps && event?.zone && (
          <>
            <span className='bg-text/60 mx-0.5 h-1 w-1 rounded-full' />
            <p>{t('event:organized_by')}</p>
            <Button
              as={Link}
              to={`/${shortLang}/zones/${event.zone?.slug}`}
              className='px-1 py-0.5 underline'
              theme='white'
              shape='text'
            >
              {event.zone?.title}
            </Button>
          </>
        )}
        {event.isStreamingEvent && (
          <>
            <span className='bg-text/60 mx-0.5 h-1 w-1 rounded-full' />
            <StreamIncludedBar event={event} />
          </>
        )}
        {event.zoneEntryIncluded && (
          <>
            <span className='bg-text/60 mx-0.5 h-1 w-1 rounded-full' />
            <ZoneIncludedBar event={event} />
          </>
        )}
      </div>

      <div className='flex items-center justify-between'>
        <div className='xs:flex-row xs:items-center flex grow flex-col items-start justify-between'>
          <h1 className='text-start text-xl sm:text-3xl'>{event.title}</h1>{' '}
          {event?.eventLogo && (
            <ContentfulImage
              className='xs:m-0 mx-auto my-4 max-h-8 w-auto object-cover px-4'
              src={event?.eventLogo?.url}
              width={event?.eventLogo?.width || 300}
              height={event?.eventLogo?.height || 300}
              alt={event.title}
              placeholder
            />
          )}
        </div>

        {/* TODO: Tags */}
        <ShareModal
          key='desktop'
          shape='outlined'
          className='hidden md:flex'
          share_description={event?.title}
          share_title={event?.title}
        />
      </div>

      {/* Mobile sticky */}
      <MobileStickyBuy event={event} />
    </>
  );
};

export default EventTitleDetails;
