import { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Carousel } from '@wbk/ui';
import { parseSectionUtm } from '@wbk/utils';
import { useCurrency } from '@wbk/currency';
import { resolveContentfulEventType } from '@/util/events';
import ContentfulImage from '../images/ContentfulImage';
import CardBackgroundMedia from '../cards/CardBackgroundMedia';
import type { ContentfulEvent, WebCard, WebSection } from '@wbk/contentful/api';

type Props = {
  section: WebSection;
};

const EventsFullScreenCarousel = ({ section }: Props) => {
  const events = section.contentCollection.items as ContentfulEvent[];
  const { lang } = useParams<{ lang: Language }>();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const renderSlide = useCallback(
    (event: ContentfulEvent, idx = 0, selected = false) => {
      const isCard = (event.__typename as unknown as string) === 'WebCard';
      const utm = parseSectionUtm({ utm: section.utm, idx, id: event.id });

      if (isCard) {
        const card = event as unknown as WebCard;
        return (
          <SlideItem
            key={card.id}
            selected={selected}
            href={card.href + utm}
            title={card.title}
            label={card.buttonLabel}
            date={card.subtitle}
            image31={card.image31?.url || card.image?.url}
            image11={card.image11?.url || card.image?.url}
            external={card?.href?.startsWith('http')}
          />
        );
      }
      const resolvedType = resolveContentfulEventType(event.__typename);
      return (
        <SlideItem
          selected={selected}
          href={`/${lang}/${resolvedType}/${event.slug}${utm}`}
          title={event.title}
          date={event.schedule?.openScheduleText}
          price={event.startingPrice}
          currency={event.currencyCode}
          image31={event.image31?.url}
          image11={event.image11?.url}
          label={event.cardButtonLabel}
        />
      );
    },
    [lang, section.utm]
  );

  if (events.length === 1) {
    const event = events[0];
    const isCard = (event.__typename as unknown as string) === 'WebCard';
    if (isCard) {
      return (
        <div className='flex h-full w-full shrink-0 grow py-4' key={event.id}>
          {renderSlide(event)}
        </div>
      );
    }
    return renderSlide(event);
  }

  const currentCard =
    events[selectedIndex] && (events[selectedIndex].__typename as unknown as string) === 'WebCard'
      ? (events[selectedIndex] as unknown as WebCard)
      : null;

  return (
    <section data-testid={`home_fullscreen_carousel_${section.id}`}>
      {currentCard && currentCard.background && (
        <CardBackgroundMedia background={currentCard.background} />
      )}

      <Carousel
        locale={lang}
        className='pb-14 pt-8'
        options={{ align: 'center', loop: true }}
        autoplay
        arrows
        dots
        onIndexChanged={(idx) => setSelectedIndex(idx)}
      >
        {({ selectedIndex }) =>
          events.map((event, i) => (
            <div
              className='flex h-full w-full shrink-0 py-4 md:mx-auto md:w-[85%] md:max-w-screen-xl'
              key={event.id}
            >
              {renderSlide(event, i, i === selectedIndex)}
            </div>
          ))
        }
      </Carousel>
    </section>
  );
};

type SlideItemType = {
  href?: string;
  selected: boolean;
  image31: string;
  image11: string;
  title: string;
  date?: string;
  price?: number;
  currency?: string;
  label?: string;
  external?: boolean;
};
const SlideItem = ({
  title,
  href,
  date,
  price,
  currency,
  selected,
  image31,
  image11,
  external,
  label,
}: SlideItemType) => {
  const { t } = useTranslation(['common', 'event']);
  const { exchangePrice, symbol } = useCurrency();

  const Content = (
    <div className='space-y-1 text-start'>
      <p className='line-clamp-2 text-xl font-semibold'>{title}</p>
      {date && <p className='text-xs text-gray-400'>{date}</p>}
      {price && price > 0 && (
        <p className='text-xs'>
          {t('event:starts_from_price', {
            price: exchangePrice(price, currency),
            currency: symbol,
          })}{' '}
          <span className='mx-0.5 text-[10px] text-gray-400'>{t('event:vat_included')}</span>
        </p>
      )}
    </div>
  );

  return (
    <Button
      as={href ? Link : 'div'}
      queueProtected
      phill={false}
      theme='transparent'
      shape='text'
      to={href}
      target={external ? '_blank' : undefined}
      className={`bg-body-light relative h-full flex-col justify-between !py-0 px-0 ring-white/50 sm:basis-[98%] md:rounded-lg ${
        selected
          ? 'md:scale-100 lg:scale-[1.03] 2xl:scale-[1.05]'
          : 'md:scale-[0.95] lg:scale-[0.96] 2xl:scale-[0.90]'
      }`}
    >
      <div className='aspect-1 h-full w-full md:aspect-[3/1]'>
        <ContentfulImage
          src={image31}
          className='hidden h-full w-full overflow-hidden object-contain object-center md:block'
          width='100%'
          height='100%'
          alt=''
          ratio='3x1'
          fallbackSrc='/images/default-poster_3x1.png'
          placeholder
        />

        <ContentfulImage
          src={image11 + '?w=600&h=600'}
          className='h-full w-full overflow-hidden object-contain object-center md:hidden'
          width={600}
          height={600}
          fit='thumb'
          alt=''
          ratio='1x1'
          fallbackSrc='/images/default-poster_1x1.png'
          placeholder
        />
      </div>

      {/* Bottom info */}
      <div className='hidden w-full items-center justify-between p-4 pt-2 md:flex'>
        {Content}
        {href && (
          <Button tabIndex={-1} className='w-full max-w-xs'>
            {label || t('event:buy')}
          </Button>
        )}
      </div>
      {/* Bottom overlay */}
      {title && (
        <div className='absolute inset-0 flex flex-col justify-between bg-gradient-to-b from-black/5 via-black/10 to-black/95 to-90% p-4 md:hidden'>
          <div />
          {Content}
        </div>
      )}
    </Button>
  );
};

export default EventsFullScreenCarousel;
