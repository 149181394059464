import { Link, useParams } from 'react-router-dom';
import { WebsiteConfig } from '@wbk/contentful/api';
import LanguageCurrencySwitch from '@/components/common/LanguageCurrencySwitch';
import UserProfileButton from '@/components/layout/header/UserProfileButton';
import DesktopSearch from '@/components/search/DesktopSearch';
import NavLink from './NavLink';

type Props = {
  type?: LayoutType;
  config: WebsiteConfig;
};

const DesktopHeader = ({ type, config }: Props) => {
  const { lang } = useParams<{ lang: Language }>();

  return (
    <nav className='container flex items-center justify-between py-3'>
      <div className='flex grow items-center gap-1 md:gap-4'>
        <Link
          data-testid='header_desktop_logo'
          to={`/${lang || ''}`}
          className='logo-image relative z-20 block shrink-0 hover:opacity-90'
          data-location='header'
          aria-label='webook.com Logo'
        >
          <img
            className='max-w-[90px] md:max-w-none'
            src={config.logo.url}
            width={89}
            height={42}
            alt=''
          />
        </Link>
        {type === 'main' && (
          <ul
            className='hidden shrink-0 items-center gap-2 text-sm md:flex lg:flex xl:flex'
            role='menu'
          >
            {config.header.topNavigationCollection.items.map((link) => {
              if (!link) return null;
              return (
                <li key={link.id} role='presentation'>
                  <NavLink
                    data-testid={`header_nav_link_${link.id}`}
                    link={link}
                    data-category={link.label}
                    aria-label={link.label}
                  />
                </li>
              );
            })}
          </ul>
        )}

        {type !== 'booking' && <DesktopSearch />}
      </div>
      <ul className='flex items-center justify-end gap-2 text-sm xl:gap-2 xl:text-base' role='menu'>
        {type === 'main' &&
          config.header.topSubNavigationCollection.items.map((link) => {
            if (!link) return null;
            return (
              <li
                data-testid={`header_nav_link_${link.id}`}
                key={link.id}
                role='presentation'
                className='hidden lg:flex'
              >
                <NavLink link={link} data-category={link.label} aria-label={link.label} />
              </li>
            );
          })}

        <li
          className='z-40 shrink-0 [&>button]:px-1 [&>button]:text-sm md:[&>button]:px-2'
          role='presentation'
        >
          <LanguageCurrencySwitch />
        </li>

        {type === 'main' && (
          <li className='relative shrink-0 text-sm' role='presentation'>
            <UserProfileButton />
          </li>
        )}
      </ul>
    </nav>
  );
};

export default DesktopHeader;
