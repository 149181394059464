import { useParams } from 'react-router-dom';
import { Product } from '@wbk/api';
import { Carousel } from '@wbk/ui';
import ShopProductItem from './ShopProductItem';

type Props = {
  products: Product[];
  showBuyButton?: boolean;
};

const ProductsCarousel = ({ products, showBuyButton }: Props) => {
  const { lang } = useParams<{ lang: Language }>();

  return (
    <Carousel locale={lang} options={{ dragFree: true }} className='py-1'>
      {products.map((product) => (
        <div
          className='relative mr-2 max-w-[200px] shrink-0 basis-[50%] sm:basis-1/3 xl:basis-1/5'
          key={product.id}
          data-testid={`home_shop_item_${product.id}`}
        >
          <ShopProductItem product={product} showBuyButton={showBuyButton} />
        </div>
      ))}
    </Carousel>
  );
};

export default ProductsCarousel;
