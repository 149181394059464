import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import AppsDownload from './AppsDownload';

type Props = {
  className?: string;
};

const DownloadAppBanner = ({ className }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <section data-testid='download_app_section' className={twMerge('container', className)}>
      <div className='my-10 flex flex-col overflow-hidden rounded-[24px] bg-violet-500 lg:flex-row lg:gap-6'>
        <div className='border-b border-white/30 bg-[url(/images/app/pattern.png)] bg-[100%_50%] lg:border-none rtl:bg-[0%_50%]'>
          <img className='mx-auto' src='/images/app/iphones.png' alt='' />
        </div>

        <div className='flex-1 self-center p-4 lg:pb-8'>
          <div className='lg:py-4'>
            <h2 className='mb-4 text-2xl font-semibold leading-tight text-gray-100 xl:text-5xl'>
              {t('common:download_app_win')}
            </h2>

            <AppsDownload />
          </div>

          <div className='my-5 hidden h-px bg-white/20 lg:block' />

          <div className='hidden items-center gap-6 lg:flex'>
            <div className='flex items-center justify-center rounded-xl bg-white p-3'>
              <img src='/images/app/qr-code.png' alt='QR Code' width={110} height={110} />
            </div>

            <div className='text-gray-100'>{t('common:scan_qr_code')}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadAppBanner;
