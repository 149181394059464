import { useEffect } from 'react';
import { ContentfulEvent } from '@wbk/contentful/api';
import { useDeviceDetect } from '@wbk/hooks';
import { BottomSheet, Button, Modal } from '@wbk/ui';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { WithGrecaptcha } from '@wbk/api/grecaptcha';
import { AnimatePresence, motion } from 'framer-motion';
import { AccessibilityIcon } from '@wbk/svg';
import useLocalization from '@/context/localization/useLocalization';
import AccessibilityInfoForm from '../accessibility/InfoForm';
import AccessibilityTicketForm from '../accessibility/TicketForm';
import AccessibilityDisabilityForm from '../accessibility/DisabilityForm';
import AccessibilitySteps from '../accessibility/Steps';
import AccessibilityFormActions from '../accessibility/Actions';
import { type AccessibilityFormValues, AccessibilityStep } from '../accessibility/types';

type Props = {
  event: ContentfulEvent;
  type?: 'event' | 'season';
  className?: string;
};

const EventAccessibilityButton = ({ event, type = 'event', className }: Props) => {
  const { isMobile } = useDeviceDetect();
  const methods = useForm<AccessibilityFormValues>({
    defaultValues: {
      slug: event.ticketingUrlSlug || event.slug,
      type,
    },
  });

  return (
    <WithGrecaptcha>
      <FormProvider {...methods}>
        {isMobile ? (
          <BottomSheet
            fullscreen
            sectionClassName='px-4 pb-8'
            render={({ open }) => <ActionButton onClick={open} className={className} />}
            body={() => <Form />}
            stickyFooter={({ close }) => <AccessibilityFormActions onClose={close} />}
          />
        ) : (
          <Modal
            closeButton
            className='overflow-y-auto p-4'
            contentClassName='max-w-xl'
            render={({ open }) => <ActionButton onClick={open} className={className} />}
            body={() => <Form />}
            stickyFooter={({ close }) => <AccessibilityFormActions onClose={close} />}
          />
        )}
      </FormProvider>
    </WithGrecaptcha>
  );
};

const Form = () => {
  const { t } = useTranslation(['event']);
  const { locale } = useLocalization();
  const { watch, reset } = useFormContext<AccessibilityFormValues>();
  const mode = watch('mode');
  const direction = watch('direction');
  const isSuccess = watch('success');

  useEffect(() => {
    // on close reset the form
    return () => {
      reset();
    };
    // eslint-disable-next-line
  }, []);

  if (isSuccess) {
    return (
      <div className='space-y-4 overflow-hidden pb-20'>
        <img
          src='/images/illustration/check.svg'
          className='mx-auto'
          width={200}
          height={200}
          alt=''
        />
        <div className='space-y-2 text-center'>
          <h2 className='pb-1 text-2xl font-semibold'>
            {t('event:accessibility.thank_you_accessibility')}
          </h2>
          <p className='text-text-600 text-sm'>
            {t('event:accessibility.thank_you_accessibility_text_1')}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className='relative space-y-4 overflow-hidden pb-20'>
      <div>
        <AccessibilityIcon className='h-10 w-10 shrink-0' />
        <h4>{t('event:accessibility.ticket_request')}</h4>
        <p className='text-text-700 text-sm'>{t('event:accessibility.ticket_request_desc')}</p>
      </div>
      <AccessibilitySteps />
      <AnimatePresence initial={false} custom={direction} mode='wait'>
        <motion.div
          key={mode}
          custom={direction}
          variants={{
            enter: (dir: number) => {
              const dirVal = locale === 'ar' ? -100 : 100;
              return {
                x: dir < 0 ? `${-dirVal}%` : `${dirVal}%`,
                opacity: 0,
              };
            },
            center: { x: 0, opacity: 1 },
            exit: (dir: number) => {
              const dirVal = locale === 'ar' ? -100 : 100;
              return {
                x: dir > 0 ? `${-dirVal}%` : `${dirVal}%`,
                opacity: 0,
              };
            },
          }}
          initial='enter'
          animate='center'
          exit='exit'
          transition={{ duration: 0.2 }}
        >
          {mode === AccessibilityStep.TICKET ? (
            <AccessibilityTicketForm />
          ) : mode === AccessibilityStep.DISABILITY ? (
            <AccessibilityDisabilityForm />
          ) : (
            <AccessibilityInfoForm />
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const ActionButton = ({ onClick, className }: { className?: string; onClick: () => void }) => {
  const { t } = useTranslation(['event']);
  return (
    <Button
      onClick={onClick}
      theme='white'
      shape='outlined'
      className={twMerge(
        'mx-auto min-h-[50px] w-full justify-center gap-1 text-lg font-semibold leading-none lg:mx-0',
        className
      )}
    >
      <AccessibilityIcon className='h-6 w-6 shrink-0' />
      {t('event:accessibility.get_tickets')}
    </Button>
  );
};

export default EventAccessibilityButton;
