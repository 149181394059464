import { useBlogList } from '@wbk/api/hooks';
import { WebSection } from '@wbk/contentful/api';
import { useWebsiteLocale } from '@wbk/hooks';
import { Carousel, ImageWithFallback } from '@wbk/ui';
import ContentfulButton from '../buttons/ContentfulButton';

type Props = {
  section: WebSection;
};

const WbkBlogSection = ({ section }: Props) => {
  const { shortLang } = useWebsiteLocale();
  const { data } = useBlogList({
    lang: shortLang,
  });

  if (!data || data.length === 0) return null;

  return (
    <section data-testid={`home_blog_section_${section.id}`} className='container space-y-7 py-12'>
      <h2>{section.title}</h2>
      <Carousel locale={shortLang} containerClassName='gap-4 py-1'>
        {data.map((article) => (
          <a
            key={article.id}
            href={article.link}
            data-testid={`home_blog_item_${article.id}`}
            className='shrink-0 basis-[95%] sm:basis-1/2 md:basis-1/3 lg:basis-1/4 xl:basis-[23%]'
          >
            <div className='aspect-1 rounded-lg md:aspect-[3/2.5]'>
              <div className='relative h-full overflow-hidden rounded-lg bg-cover transition duration-500'>
                <div className='absolute inset-0 z-10 scale-105 bg-gradient-to-b from-black/5 via-black/60 to-black/95 to-90%' />
                <ImageWithFallback
                  className='aspect-1 absolute h-full object-cover transition duration-300 group-hover:scale-105 group-focus:scale-105'
                  src={article.featured_image_url}
                  width={800}
                  height={800}
                  alt={article.title.rendered}
                />

                <div className='relative z-20 flex h-full w-full flex-col justify-between overflow-hidden p-4'>
                  <div />

                  <div className='space-y-2'>
                    <div
                      className='line-clamp-2 text-xl'
                      dangerouslySetInnerHTML={{ __html: article.title.rendered }}
                    />
                    {article.excerpt.rendered && (
                      <div
                        className='line-clamp-2 text-sm'
                        dangerouslySetInnerHTML={{ __html: article.excerpt.rendered }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </a>
        ))}
      </Carousel>

      <div className='flex items-center justify-center gap-4'>
        {section.webLinksCollection.items.map((link) => (
          <ContentfulButton data-testid='home_blog_read_more_button' key={link.id} link={link} />
        ))}
      </div>
    </section>
  );
};

export default WbkBlogSection;
