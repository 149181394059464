import { useTranslation } from 'react-i18next';
import { Menu } from '@wbk/ui';
import useFilters from '@/context/filters/useSearch';

const sortList = [
  { text: 'most_popular', value: '' },
  { text: 'latest', value: 'latest' },
  { text: 'oldest', value: 'oldest' },
  { text: 'price_low_to_high', value: 'low_price' },
  { text: 'price_high_to_low', value: 'high_price' },
];

type Props = {
  totalEvents: number;
};

const ExploreSortFilter = ({ totalEvents }: Props) => {
  const { t } = useTranslation('common');
  const { sort, selectFilter } = useFilters();

  if (totalEvents <= 0) return null;

  return (
    <div className='flex shrink-0 items-center gap-1'>
      <p className='text-text-500'>{t('common:result_and_sort', { total: totalEvents })}</p>
      <Menu
        className='hover:bg-text/5 focus:bg-text/5 gap-0 bg-transparent p-0.5 text-base font-semibold underline !ring-0'
        value={sort || ''}
        label={t('common:sort')}
        onChange={(selected) => {
          selectFilter({ key: 'sort', value: selected.value });
        }}
        options={sortList.map((op) => ({
          id: op.value,
          text: t(op.text),
          value: op.value,
        }))}
      />
    </div>
  );
};

export default ExploreSortFilter;
