import { useLayoutEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { Button } from '@wbk/ui';
import { ContentfulRichText as RichText } from '@wbk/contentful/api';
import MarkdownRender from './MarkdownRender';
import ContentfulRichText from './ContentfulRichText';

type Props = {
  text?: string | RichText;
  /** Minimun lines to activate the collapse */
  minimum?: keyof typeof lineClamps;
  btnProps?: Parameters<typeof Button>[0];
  className?: string;
  markdown?: boolean;
  children?: React.ReactNode;
};

const lineClamps = {
  1: 'line-clamp-1',
  2: 'line-clamp-2',
  3: 'line-clamp-3',
  4: 'line-clamp-4',
  5: 'line-clamp-5',
  6: 'line-clamp-6',
  12: 'line-clamp-[12]',
  none: 'line-clamp-none',
};

const CollapsableText = ({ text, minimum = 3, className, btnProps, markdown, children }: Props) => {
  const { t } = useTranslation('common');

  const [showExpandBtn, setShowExpandBtn] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState<'auto' | number>('auto');
  const collapsedHeightRef = useRef<typeof height>('auto');

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const { clientHeight, scrollHeight } = ref.current;
    setShowExpandBtn(scrollHeight > clientHeight);
    setHeight(clientHeight);
    collapsedHeightRef.current = clientHeight;
  }, []);

  const toggle = () => {
    setIsExpanded(!isExpanded);

    requestAnimationFrame(() => {
      if (!ref.current) return;
      setHeight(isExpanded ? collapsedHeightRef.current : ref.current.scrollHeight);
    });
  };

  if (!text && !children) return null;

  return (
    <div className='relative inline-block w-full'>
      <motion.div
        transition={{ duration: 0.25, ease: 'easeInOut' }}
        className='overflow-hidden'
        animate={{ height }}
        style={{ height }}
      >
        <div
          ref={ref}
          data-testid='collapsable-text'
          className={twMerge(
            'overflow-hidden',
            !isExpanded && lineClamps[minimum],
            markdown ? 'text-text' : 'text-text max-w-full whitespace-pre-wrap',
            (markdown || (typeof text !== 'string' && !children)) && 'prose',
            className
          )}
        >
          {children ? (
            children
          ) : markdown ? (
            <MarkdownRender text={text as string} />
          ) : typeof text === 'string' ? (
            <div dangerouslySetInnerHTML={{ __html: text }} />
          ) : (
            <ContentfulRichText description={text!} />
          )}
        </div>
      </motion.div>

      {showExpandBtn && (
        <Button
          onClick={toggle}
          shape='text'
          theme='transparent'
          className='relative p-px text-sm underline ring-0 hover:underline'
          phill={false}
          rounded
          aria-label={isExpanded ? t('common:read_less') : t('common:read_more')}
          {...btnProps}
        >
          {isExpanded ? t('common:read_less') : t('common:read_more')}
        </Button>
      )}
    </div>
  );
};

export default CollapsableText;
