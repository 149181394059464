import { useMemo } from 'react';
import { Input, Select, ToggleGroup } from '@wbk/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { COUNTRIES, dateAdd, parseDateString, sortedCities } from '@wbk/utils';
import { useAuth, useEmailSpelling } from '@wbk/auth';
import { MIN_AGE_ALLOWED } from '@/constants/age';
import useLocalization from '@/context/localization/useLocalization';
import { type AccessibilityFormValues, AccessibilityStep } from './types';

const AccessibilityInfoForm = () => {
  const { locale } = useLocalization();
  const { user } = useAuth();
  const {
    setValue,
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext<AccessibilityFormValues>();
  const { t } = useTranslation(['common', 'event']);

  const mode = watch('mode');
  const email = watch('email');
  const countryOfResidence = watch('country') || user?.country;

  const emailSuggestion = useEmailSpelling({ email });

  const onSubmit = () => {
    setValue('mode', AccessibilityStep.DISABILITY);
  };

  const saudiCities = useMemo(() => {
    return sortedCities(locale);
  }, [locale]);

  return (
    <form className='space-y-4' id={`accessibility_step_${mode}`} onSubmit={handleSubmit(onSubmit)}>
      <fieldset className='flex flex-col gap-2 py-1 sm:flex-row'>
        <div className='grow'>
          <Input
            id='first_name'
            label={t('event:accessibility.first_name')}
            defaultValue={user?.first_name || ''}
            {...register('first_name', {
              required: t('common:validation.required'),
              maxLength: {
                value: 25,
                message: t('common:validation.max_length', { max: 25 }),
              },
            })}
            onChange={(value) => {
              setValue('first_name', value);
            }}
            error={errors.first_name?.message}
          />
        </div>

        <div className='grow'>
          <Input
            id='last_name'
            autoComplete='off'
            label={t('event:accessibility.last_name')}
            defaultValue={user?.last_name || ''}
            {...register('last_name', {
              required: t('common:validation.required'),
              maxLength: {
                value: 25,
                message: t('common:validation.max_length', { max: 25 }),
              },
            })}
            onChange={(value) => {
              setValue('last_name', value);
            }}
            error={errors.last_name?.message}
          />
        </div>
      </fieldset>

      <Input
        type='date'
        className='w-full'
        pattern='\d{4}-\d{2}-\d{2}'
        max={
          dateAdd({
            date: new Date(),
            value: -MIN_AGE_ALLOWED,
            interval: 'year',
          })
            ?.toISOString()
            ?.split('T')?.[0]
        }
        label={t('event:accessibility.birthdate')}
        defaultValue={parseDateString(user?.dob) || ''}
        {...register('dob', { required: t('common:validation.required') })}
        onChange={(value) => {
          setValue('dob', value);
        }}
        error={errors.dob?.message}
      />

      <Controller
        name='nationality'
        control={control}
        rules={{ required: t('common:validation.required') }}
        defaultValue={user?.nationality || ''}
        render={({ field: { onChange, ...props } }) => (
          <Select
            id='nationality'
            {...props}
            portal={false}
            autoComplete='off'
            onChange={(selected) => onChange(selected?.value || '')}
            label={t('event:accessibility.nationality')}
            options={COUNTRIES.map((c) => ({
              id: c.code,
              value: c.code,
              text: c[`name_${locale}` as 'name'] || c.name,
              icon: (
                <img
                  className='shrink-0'
                  src={`https://wbk-assets.s3.me-south-1.amazonaws.com/flags/w80/${c.code.toLowerCase()}.png`}
                  width={20}
                  height={14}
                  alt={c.name}
                />
              ),
            }))}
            error={errors.nationality?.message}
          />
        )}
      />

      <Controller
        name='gender'
        control={control}
        rules={{ required: t('common:validation.required') }}
        defaultValue={user?.gender || ''}
        render={({ field }) => (
          <ToggleGroup
            label={t('event:accessibility.gender')}
            options={[
              { text: t('event:accessibility.male'), value: 'male' },
              { text: t('event:accessibility.female'), value: 'female' },
              { text: t('event:accessibility.prefer_not_to_say'), value: 'prefer_not_to_say' },
            ]}
            {...field}
            type='single'
            error={errors.gender?.message}
          />
        )}
      />

      <Controller
        name='country'
        control={control}
        rules={{ required: t('common:validation.required') }}
        defaultValue={user?.country || ''}
        render={({ field: { onChange, ...props } }) => (
          <Select
            id='country'
            {...props}
            portal={false}
            autoComplete='off'
            onChange={(selected) => onChange(selected?.value || '')}
            label={t('event:accessibility.country_of_residence')}
            options={COUNTRIES.map((c) => ({
              id: c.code,
              value: c.code,
              text: c[`name_${locale}` as 'name'] || c.name,
              icon: (
                <img
                  className='shrink-0'
                  src={`https://wbk-assets.s3.me-south-1.amazonaws.com/flags/w80/${c.code.toLowerCase()}.png`}
                  width={20}
                  height={14}
                  alt={c.name}
                />
              ),
            }))}
            error={errors.country?.message}
          />
        )}
      />

      {countryOfResidence === 'SA' && (
        <Controller
          name='city'
          control={control}
          rules={{ required: t('common:validation.required') }}
          defaultValue={user?.city || ''}
          render={({ field: { onChange, ...props } }) => (
            <Select
              id='city'
              autoComplete='off'
              {...props}
              portal={false}
              onChange={(selected) => onChange(selected?.value || '')}
              label={t('event:accessibility.city')}
              options={saudiCities}
              error={errors.city?.message}
            />
          )}
        />
      )}

      <div className='relative'>
        <Controller
          control={control}
          name='email'
          rules={{ required: t('common:validation.required') }}
          defaultValue={user?.email || ''}
          render={({ field: { ...props } }) => (
            <Input
              id='email'
              autoComplete='off'
              label={t('event:accessibility.email_to_receive_tickets')}
              {...props}
              error={errors.email?.message}
            />
          )}
        />
        <p className='pt-0.5 text-sm text-gray-400'>{t('event:accessibility.email_notice')}</p>
        {emailSuggestion && (
          <div
            className={`absolute w-full rounded-lg bg-yellow-100 p-2 text-sm text-gray-800 ${errors.email?.message ? 'bottom-0' : '-bottom-4'}`}
          >
            {t('common:auth.did_you_mean')}
            <button
              onClick={() =>
                setValue('email', emailSuggestion.full, {
                  shouldValidate: true,
                })
              }
              className='text-primary mx-1 cursor-pointer font-semibold hover:underline'
            >
              {emailSuggestion.full}
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

export default AccessibilityInfoForm;
